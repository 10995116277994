<template>
  <div id='file-menu-container' v-if="fileMenuOpen">
    <div class="file-menu">
      <div class='title'>Open Project...</div>
      <svg id="exit-icon" @click="$emit('close-menu')" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M18.3,5.71L18.3,5.71c-0.39-0.39-1.02-0.39-1.41,0L12,10.59L7.11,5.7c-0.39-0.39-1.02-0.39-1.41,0l0,0 c-0.39,0.39-0.39,1.02,0,1.41L10.59,12L5.7,16.89c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0L12,13.41l4.89,4.89 c0.39,0.39,1.02,0.39,1.41,0l0,0c0.39-0.39,0.39-1.02,0-1.41L13.41,12l4.89-4.89C18.68,6.73,18.68,6.09,18.3,5.71z"/></g></g></svg>
      <div class="file-list">
        <div class="project-item" v-for="project in savedProjects" :key="project.id">
          <div class="project-name clickable" :title="project.name+'('+project.date+')'" @click="openProject(project.id)">
            <span v-show="project.id == currentProject.id">*</span>
            {{project.name}}
            <span class='project-date'>{{project.date}}</span>
          </div>
          
          <div class="project-options">
            <svg class="clickable" @click='downloadProject(project.id, project.name)' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>          
            <span class="clickable" @click='renameProject(project.id, project.name)'>Rename</span>
            <span class="clickable" @click='deleteProject(project.id)'>Delete</span>    
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    fileMenuOpen: Boolean,
    savedProjects: Array,
    currentProject: Object
  },
  methods : {
    openProject (id) {
      this.$emit('load-project', id);
      this.$emit('close-menu');
    },
    deleteProject(id) {
      this.$emit('delete-project', id);
    },
    renameProject(id, name) {
      this.$emit('rename-project', id, name);

    },
    downloadProject(id, name) {
      let xml_text = localStorage.getItem('project_workspace_'+id);
      this.$emit('download-project', xml_text, name);
    }
  }
}
</script>

<style>
#file-menu-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, .75);
  z-index: 1000;
}
.file-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #242323;
  margin: 24px;
  border-radius: 10px;
  color: #fafafa;
  font-size: 24px;
}
#exit-icon {
  position: absolute;
  right: 14px;
  top: 14px;
  height: 40px;
  width: auto;
  fill: #bbb;
  cursor: pointer;
}
#exit-icon:hover {
  fill: #6f0;
}
.title {
  font-size: 32px;
  padding: 16px;
  line-height: 1;
  color: #bbb;
  font-weight: 400;
  cursor: default;
}
.file-list {
  position: absolute;
  overflow-y: auto;
  bottom: 12px;
  top: 64px;
  left: 24px;
  right: 12px;
  padding-right: 12px;
}
.project-item {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px black solid;
  padding-bottom: 6px;
  padding-top: 6px;
}
.project-name {
  overflow: hidden;
}
.project-date {
  color: #bbb;
  font-weight: 300;
  font-size: 16px;
  padding-left: 6px;
}
.project-options {
  display: flex;
  align-items: center;
}
.project-options span {
  padding-left: 6px;
  /* text-transform: uppercase; */
  font-size: 22px;
}
.project-options svg {
  fill: #fafafa;
  height: 32px;
  width: auto;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #6f0;
  fill: #6f0;
}
</style>