<template>
    <codemirror ref="codemirror" class='cm-component' v-model="code" :options="codemirrorOptions"/>
</template>

<script>
import CodeMirror from 'codemirror';
// console.log(CodeMirror);
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/javascript-hint.js';

import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/javascript-lint.js';
import { JSHINT } from 'jshint';
window.JSHINT = JSHINT;
import droneLinter from '../codemirror-custom-addons/drone-lint.js';

import { codemirror } from 'vue-codemirror';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/comment/comment.js';
import '../codemirror-custom-addons/autocomplete.js';

export default {
  name: "TextEditor",
  components : {
    codemirror
  },
  props: {
    startingCode: String
  },
  data () {
    return {
      code: this.startingCode,
      codemirrorOptions: {
        lineNumbers: true,
        mode: 'text/javascript',
        tabSize: 2,
        styleActiveLine: true,
        line: true,
        theme: 'monokai',
        autoCloseBrackets: true,
        matchBrackets: true,
        gutters: ['CodeMirror-lint-markers'],
        // lint: { 'esversion': '8' }, 
        lint: {async: true, getAnnotations: droneLinter, 
          options: {
            'esversion': '8', 
            undef: true, 
            globals:{
              'drone': true,
              'droneEventListeners': true,
              'keyPressListeners': true,
              'startProgram': true,
              'stopProgram': true,
              'keysPressed': true,
              'console': true,
              'sin': true,
              'cos': true,
              'tan': true,
              'asin': true,
              'acos': true,
              'atan': true,
              'repeatForSeconds': true
            } 
            }
        },
        hint: CodeMirror.hint.drone
      }
    }
  },
  computed : {
    cmInstance () {
      return this.$refs.codemirror.codemirror;
    },
    codeIsEventBased () {
      //looks for any (uncommented) event listeners in the code 
      let editor = this.cmInstance;
      let str = this.cmInstance.getValue();
      let re = /droneEventListeners|keyPressListeners/g;
      let match;
      while ((match = re.exec(str))!=null) {
        let start = match.index;
        let from = editor.posFromIndex(start);
        if ( editor.getTokenAt(from).type != 'comment') return true;
      }
      return false;
    }
  },
  methods: {
    isErrorFree (strict=false) {
    //returns true if the code in the editor contains errors 
    //if strict - include warnings

      let cmString = this.cmInstance.getValue();
      let allErrors =  droneLinter(cmString, ()=>{}, {
            'esversion': '8', 
            undef: true, 
            globals:{
              'drone': true,
              'droneEventListeners': true,
              'keyPressListeners': true,
              'startProgram': true,
              'stopProgram': true,
              'keysPressed': true,
              'console': true,
              'sin': true,
              'cos': true,
              'tan': true,
              'asin': true,
              'acos': true,
              'atan': true,
              'repeatForSeconds': true
            } 
            }, this.cmInstance);
      //filter out warnings
      let severities = ['error'];
      if(strict) severities.push('warning');
      allErrors = allErrors.filter(error=>severities.includes(error.severity));
      return allErrors.length == 0;
    }
  },
  watch : {
    code () {
      this.$emit('code-modified');
    }
  },
  mounted() {
    //set up autocomplete on any input
    this.cmInstance.on('inputRead', (cm)=>{
      cm.showHint({
        hint: CodeMirror.hint.drone,
        completeSingle: false
      });
    });
    this.cmInstance.setOption ('extraKeys', {
      'Ctrl-/': function(cm) {
        cm.execCommand('toggleComment');
      }
    });
  },
  
}
</script>

<style>
.CodeMirror {
  height: 100%; 
  width: 100%;
}
.cm-component{
 height: 100%;
 width: 100%;
}
.CodeMirror-lint-marker-none {
  display: none;
}
</style>