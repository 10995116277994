import * as Blockly from 'blockly/core';

const MINIDRONE_COLOR = "#242323";
const GENERAL_COLOR = "#D4434B";

Blockly.Blocks['minidrone_takeoff'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel('take off Mini Drone', 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_land'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("land Mini Drone", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_rotate'] = {
  init: function() {
    this.appendValueInput("DEGREES")
        .setCheck("Number")
        .appendField(new Blockly.FieldLabel("rotate Mini Drone", 'blocklyTextGreen'));
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("degrees", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["clockwise","clockwise"], ["counterclockwise","counterclockwise"]]), "direction");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_fly'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("fly Mini Drone", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["forward","forward"], ["backward","backward"], ["right","right"], ["left","left"], ["up","up"], ["down","down"]]), "direction");
    this.appendValueInput('seconds')
        .setCheck("Number")
        .appendField(new Blockly.FieldLabel("for", 'blocklyTextGreen'));
    this.appendValueInput("power")
        .setCheck("Number")
        .appendField(new Blockly.FieldLabel("seconds at", 'blocklyTextGreen'));
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("% power", 'blocklyTextGreen'));
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_flip'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("flip Mini Drone", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["forward","forward"], ["backward","backward"], ["right","right"], ["left","left"]]), "direction");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_cutoff'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("cutoff Mini Drone", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_get_battery_level'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("get Mini Drone battery level", 'blocklyTextGreen'));
    this.setOutput(true, "Number");
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['program_start'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("program start");
    this.appendStatementInput("main_code")
        .setCheck(null)
        .setAlign(Blockly.ALIGN_RIGHT);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['wait'] = {
  init: function() {
    this.appendValueInput("seconds")
        .setCheck("Number")
        .appendField("wait for ");
    this.appendDummyInput()
        .appendField("seconds");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_wait_until_battery_changes'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("wait until Mini Drone battery level changes", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_flying_state'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("Mini Drone is ", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["landed","landed"], ["flying", "flying"]]), "flying_state");
    this.setOutput(true, "Boolean");
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['custom_print'] = {
  init: function() {
    this.appendValueInput("message")
        .setCheck(["Boolean", "Number", "String"])
        .appendField("print");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

//adds repeat_forever to the types of blocks that allow a 'break' or 'continue' block to be nested within them
Blockly.Constants.Loops.CONTROL_FLOW_IN_LOOP_CHECK_MIXIN.LOOP_TYPES.push('repeat_forever');

Blockly.Blocks['repeat_forever'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("repeat forever");
    this.appendStatementInput("block_of_code")
        .setCheck(null)
        .appendField("do");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour('#5ba55b');
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['repeat_seconds'] = {
  init: function() {
    this.appendValueInput("seconds")
        .setCheck(null)
        .appendField("repeat for");
    this.appendDummyInput()
        .appendField("seconds");
    this.appendStatementInput("do")
        .setCheck(null)
        .appendField("do");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour('#5ba55b');
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['stop_program'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("stop program");
    this.setPreviousStatement(true, null);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['is_key_pressed'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("is")
        .appendField(new Blockly.FieldDropdown([["up arrow","ArrowUp"], ["down arrow","ArrowDown"], ["right arrow","ArrowRight"], ["left arrow","ArrowLeft"], ["space","Space"], 
          [ 'a', 'a' ], [ 'b', 'b' ],
          [ 'c', 'c' ], [ 'd', 'd' ],
          [ 'e', 'e' ], [ 'f', 'f' ],
          [ 'g', 'g' ], [ 'h', 'h' ],
          [ 'i', 'i' ], [ 'j', 'j' ],
          [ 'k', 'k' ], [ 'l', 'l' ],
          [ 'm', 'm' ], [ 'n', 'n' ],
          [ 'o', 'o' ], [ 'p', 'p' ],
          [ 'q', 'q' ], [ 'r', 'r' ],
          [ 's', 's' ], [ 't', 't' ],
          [ 'u', 'u' ], [ 'v', 'v' ],
          [ 'w', 'w' ], [ 'x', 'x' ],
          [ 'y', 'y' ], [ 'z', 'z' ]
        ]), "key")
        .appendField(new Blockly.FieldDropdown([["pressed","pressed"], ["released","released"]]), "pressed_or_released");
    this.setOutput(true, "Boolean");
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['continue_if'] = {
  init: function() {
    this.appendValueInput("condition")
        .setCheck("Boolean")
        .appendField("continue if");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_take_picture'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("take picture with Mini Drone", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_fire_bb'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("fire BB for Mini Drone", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['event_when_key_pressed'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("when")
        .appendField(new Blockly.FieldDropdown([["up arrow","ArrowUp"], ["down arrow","ArrowDown"], ["right arrow","ArrowRight"], ["left arrow","ArrowLeft"], ["space","Space"], 
          [ 'a', 'a' ], [ 'b', 'b' ],
          [ 'c', 'c' ], [ 'd', 'd' ],
          [ 'e', 'e' ], [ 'f', 'f' ],
          [ 'g', 'g' ], [ 'h', 'h' ],
          [ 'i', 'i' ], [ 'j', 'j' ],
          [ 'k', 'k' ], [ 'l', 'l' ],
          [ 'm', 'm' ], [ 'n', 'n' ],
          [ 'o', 'o' ], [ 'p', 'p' ],
          [ 'q', 'q' ], [ 'r', 'r' ],
          [ 's', 's' ], [ 't', 't' ],
          [ 'u', 'u' ], [ 'v', 'v' ],
          [ 'w', 'w' ], [ 'x', 'x' ],
          [ 'y', 'y' ], [ 'z', 'z' ]
        ]), "key")
        .appendField("key")
        .appendField(new Blockly.FieldDropdown([["pressed","pressed"], ["released","released"]]), "pressed_or_released");
    this.appendStatementInput("do")
        .setCheck(null);
    this.setInputsInline(true);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_hover'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("hover Mini Drone", 'blocklyTextGreen'));
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_set_direction'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("set Mini Drone", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["pitch","pitch"], ["yaw","yaw"], ["roll","roll"], ["altitude","gaz"]]), "axis");
    this.appendValueInput("NAME")
        .setCheck("Number")
        .appendField(new Blockly.FieldLabel("to", 'blocklyTextGreen'));
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("%", 'blocklyTextGreen'));
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['event_when_minidrone_flying_state'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("when Mini Drone state is", 'blocklyTextGreen'))
        .appendField(new Blockly.FieldDropdown([["flying","flying"], ["landed","landed"]]), "STATE");
    this.appendStatementInput("DO")
        .setCheck(null);
    this.setInputsInline(true);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['event_when_minidrone_crashes'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("when Mini Drone crashes", 'blocklyTextGreen'));
    this.appendStatementInput("DO")
        .setCheck(null);
    this.setInputsInline(true);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['event_when_minidrone_battery_level_changes'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabel("when Mini Drone battery level changes", 'blocklyTextGreen'));
    this.appendStatementInput("DO")
        .setCheck(null);
    this.setInputsInline(true);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_reset'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("reset Mini Drone");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(GENERAL_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['minidrone_grabber'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["open","OPEN"], ["close","CLOSE"]]), "openOrClose")
        .appendField("grabber");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(MINIDRONE_COLOR);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};


Blockly.JavaScript['minidrone_takeoff'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.takeOff();\n';
  return code;
};

Blockly.JavaScript['minidrone_land'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.land();\n';
  return code;
};

Blockly.JavaScript['minidrone_hover'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.hover();\n';
  return code;
};

Blockly.JavaScript['minidrone_rotate'] = function(block) {
  var value_degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  var dropdown_direction = block.getFieldValue('direction');
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.rotate(' + value_degrees + ', "' + dropdown_direction + '");\n';
  return code;
};

Blockly.JavaScript['minidrone_fly'] = function(block) {
  var dropdown_direction = block.getFieldValue('direction');
  var value_seconds = Blockly.JavaScript.valueToCode(block, 'seconds', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  var value_power = Blockly.JavaScript.valueToCode(block, 'power', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.fly("' + dropdown_direction + '", ' + value_seconds + ', ' + value_power + ');\n';
  return code;
};

Blockly.JavaScript['minidrone_set_direction'] = function(block) {
  var dropdown_axis = block.getFieldValue('axis');
  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  // TODO: Assemble JavaScript into code variable.
  var code = 'drone.setAxis("'+dropdown_axis+'", '+value_name+');\n';
  return code;
};

Blockly.JavaScript['minidrone_flip'] = function(block) {
  var dropdown_direction = block.getFieldValue('direction');
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.flip("' + dropdown_direction + '");\n';
  return code;
};

Blockly.JavaScript['minidrone_cutoff'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.cutoff();\n';
  return code;
};

Blockly.JavaScript['minidrone_get_battery_level'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'drone.getBatteryLevel()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['program_start'] = function(block) {
  var statements_main_code = Blockly.JavaScript.statementToCode(block, 'main_code');
  // TODO: Assemble JavaScript into code variable.
  var code = 'startProgram = async function () {\n'+statements_main_code+'};\n';
  return code;
};

Blockly.JavaScript['wait'] = function(block) {
  var value_seconds = Blockly.JavaScript.valueToCode(block, 'seconds', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.wait('+value_seconds+');\n';
  return code;
};

Blockly.JavaScript['minidrone_wait_until_battery_changes'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.waitUntilBatteryLevelChanges();\n';
  return code;
};

Blockly.JavaScript['minidrone_flying_state'] = function(block) {
  var dropdown_flying_state = block.getFieldValue('flying_state');
  // TODO: Assemble JavaScript into code variable.
  var code;
  if (dropdown_flying_state == 'flying') {
    code = 'drone.isFlying()';
  } else {
    code = 'drone.isLanded()';
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['minidrone_take_picture'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.takePicture();\n';
  return code;
};

Blockly.JavaScript['minidrone_fire_bb'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.fireGun();\n';
  return code;
};

Blockly.JavaScript['custom_print'] = function(block) {
  var value_message = Blockly.JavaScript.valueToCode(block, 'message', Blockly.JavaScript.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = 'console.log(' + value_message + ');\n';
  return code;
};

Blockly.JavaScript['repeat_forever'] = function(block) {
  var statements_block_of_code = Blockly.JavaScript.statementToCode(block, 'block_of_code');
  // TODO: Assemble JavaScript into code variable.
  statements_block_of_code = Blockly.JavaScript.addLoopTrap(statements_block_of_code, block);
  var code = 'while (true) {\n'+statements_block_of_code+'}\n';
  return code;
};

Blockly.JavaScript['repeat_seconds'] = function(block) {
  var value_seconds = Blockly.JavaScript.valueToCode(block, 'seconds', Blockly.JavaScript.ORDER_ATOMIC);
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
  statements_do = Blockly.JavaScript.addLoopTrap(statements_do, block);
  // TODO: Assemble JavaScript into code variable.
  var code = 'await repeatForSeconds('+value_seconds+', async function() {\n'+statements_do+'});\n';
  return code;
};

Blockly.JavaScript['stop_program'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'stopProgram();\n';
  return code;
};

Blockly.JavaScript['is_key_pressed'] = function(block) {
  var dropdown_key = block.getFieldValue('key');
  var dropdown_pressed_or_released = block.getFieldValue('pressed_or_released');
  // TODO: Assemble JavaScript into code variable.
  var code = 'keysPressed.'+dropdown_key;
  if(dropdown_pressed_or_released == 'released') {
    code = '!' + code;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_LOGICAL_NOT];
};

Blockly.JavaScript['continue_if'] = function(block) {
  var value_condition = Blockly.JavaScript.valueToCode(block, 'condition', Blockly.JavaScript.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = 'if (!'+value_condition+') return;\n';
  return code;
};

Blockly.JavaScript['event_when_key_pressed'] = function(block) {
  var dropdown_key = block.getFieldValue('key');
  var dropdown_pressed_or_released = block.getFieldValue('pressed_or_released');
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
  // TODO: Assemble JavaScript into code variable.
  var code = 'keyPressListeners.'+dropdown_pressed_or_released+'.'+dropdown_key+' = async function () {\n'+statements_do+'};\n';
  return code;
};

Blockly.JavaScript['event_when_minidrone_flying_state'] = function(block) {
  var dropdown_state = block.getFieldValue('STATE');
  var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
  // TODO: Assemble JavaScript into code variable.
  var code = 'droneEventListeners.'+dropdown_state+' = async function () {\n'+statements_do+'};\n';
  return code;
};

Blockly.JavaScript['event_when_minidrone_crashes'] = function(block) {
  var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
  // TODO: Assemble JavaScript into code variable.
  var code = 'droneEventListeners.crashed = async function () {\n'+statements_do+'};\n';
  return code;
};

Blockly.JavaScript['event_when_minidrone_battery_level_changes'] = function(block) {
  var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
  // TODO: Assemble JavaScript into code variable.
  var code = 'droneEventListeners.batteryLevelChanged = async function () {\n'+statements_do+'};\n';
  return code;
};

Blockly.JavaScript['minidrone_reset'] = function() {
  // TODO: Assemble JavaScript into code variable.
  var code = 'drone.reset();\n';
  return code;
};

Blockly.JavaScript['minidrone_grabber'] = function(block) {
  var dropdown_openorclose = block.getFieldValue('openOrClose');
  // TODO: Assemble JavaScript into code variable.
  var code = 'await drone.grabber("'+dropdown_openorclose+'");\n';
  return code;
};

//Makes functions async by manually adding 'async' and 'await' keywords to function defs and calls

Blockly.JavaScript['procedures_defreturn'] = function(block) {
  
  // Define a procedure with a return value.
  var funcName = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('NAME'), Blockly.PROCEDURE_CATEGORY_NAME);
  var xfix1 = '';
  if (Blockly.JavaScript.STATEMENT_PREFIX) {
    xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_PREFIX,
        block);
  }
  if (Blockly.JavaScript.STATEMENT_SUFFIX) {
    xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
        block);
  }
  if (xfix1) {
    xfix1 = Blockly.JavaScript.prefixLines(xfix1, Blockly.JavaScript.INDENT);
  }
  var loopTrap = '';
  if (Blockly.JavaScript.INFINITE_LOOP_TRAP) {
    loopTrap = Blockly.JavaScript.prefixLines(
        Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP,
        block), Blockly.JavaScript.INDENT);
  }
  var branch = Blockly.JavaScript.statementToCode(block, 'STACK');
  var returnValue = Blockly.JavaScript.valueToCode(block, 'RETURN',
      Blockly.JavaScript.ORDER_NONE) || '';
  var xfix2 = '';
  if (branch && returnValue) {
    // After executing the function body, revisit this block for the return.
    xfix2 = xfix1;
  }
  if (returnValue) {
    returnValue = Blockly.JavaScript.INDENT + 'return ' + returnValue + ';\n';
  } else {
    returnValue = Blockly.JavaScript.INDENT + 'return;\n'
  }
  var args = [];
  var variables = block.getVars();
  for (var i = 0; i < variables.length; i++) {
    args[i] = Blockly.JavaScript.variableDB_.getName(variables[i],
        Blockly.VARIABLE_CATEGORY_NAME);
  }
  var code = 'async function ' + funcName + '(' + args.join(', ') + ') {\n' +
      xfix1 + loopTrap + branch + xfix2 + returnValue + '}';
  code = Blockly.JavaScript.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  Blockly.JavaScript.definitions_['%' + funcName] = code;
  return null;
};

//functions with no return don't get a return statement added in

Blockly.JavaScript['procedures_defnoreturn'] = function(block) {
  
  // Define a procedure with a return value.
  var funcName = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('NAME'), Blockly.PROCEDURE_CATEGORY_NAME);
  var xfix1 = '';
  if (Blockly.JavaScript.STATEMENT_PREFIX) {
    xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_PREFIX,
        block);
  }
  if (Blockly.JavaScript.STATEMENT_SUFFIX) {
    xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
        block);
  }
  if (xfix1) {
    xfix1 = Blockly.JavaScript.prefixLines(xfix1, Blockly.JavaScript.INDENT);
  }
  var loopTrap = '';
  if (Blockly.JavaScript.INFINITE_LOOP_TRAP) {
    loopTrap = Blockly.JavaScript.prefixLines(
        Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP,
        block), Blockly.JavaScript.INDENT);
  }
  var branch = Blockly.JavaScript.statementToCode(block, 'STACK');
  var returnValue = Blockly.JavaScript.valueToCode(block, 'RETURN',
      Blockly.JavaScript.ORDER_NONE) || '';
  var xfix2 = '';
  if (branch && returnValue) {
    // After executing the function body, revisit this block for the return.
    xfix2 = xfix1;
  }
  var args = [];
  var variables = block.getVars();
  for (var i = 0; i < variables.length; i++) {
    args[i] = Blockly.JavaScript.variableDB_.getName(variables[i],
        Blockly.VARIABLE_CATEGORY_NAME);
  }
  var code = 'async function ' + funcName + '(' + args.join(', ') + ') {\n' +
      xfix1 + loopTrap + branch + xfix2 + returnValue + '}';
  code = Blockly.JavaScript.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  Blockly.JavaScript.definitions_['%' + funcName] = code;
  return null;
};

Blockly.JavaScript['procedures_callreturn'] = function(block) {
  // Call a procedure with a return value.
  var funcName = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('NAME'), Blockly.PROCEDURE_CATEGORY_NAME);
      
  var args = [];
  var variables = block.arguments_;
  for (var i = 0; i < variables.length; i++) {
    args[i] = Blockly.JavaScript.valueToCode(block, 'ARG' + i,
        Blockly.JavaScript.ORDER_NONE) || 'null';
  }
  var code = 'await ' + funcName + '(' + args.join(', ') + ')';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['procedures_callnoreturn'] = function(block) {
  // Call a procedure with no return value.
  // Generated code is for a function call as a statement is the same as a
  // function call as a value, with the addition of line ending.
  var tuple = Blockly.JavaScript['procedures_callreturn'](block);
  return tuple[0] + ';\n';
};

/*
Override built-in trig functions
*/

Blockly.JavaScript['math_trig'] = function(block) {
  var operator = block.getFieldValue('OP');
  var arg = Blockly.JavaScript.valueToCode(block, 'NUM',
        Blockly.JavaScript.ORDER_NONE) || '0';
  var code;
  switch (operator) {
    case 'SIN':
      code = 'sin(' + arg + ')';
      break;
    case 'COS':
      code = 'cos(' + arg + ')';
      break;
    case 'TAN':
      code = 'tan(' + arg + ')';
      break;
    case 'ASIN':
      code = 'asin(' + arg + ')';
      break;
    case 'ACOS':
      code = 'acos(' + arg + ')';
      break;
    case 'ATAN':
      code = 'atan(' + arg + ')';
      break;
    
  }
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
}


/*Add custom function blocks to the list of those that can have a return block*/
let customFunctionBlocks = ['program_start', 'event_when_key_pressed','event_when_minidrone_battery_level_changes','event_when_minidrone_crashes','event_when_minidrone_flying_state']
Blockly.Blocks['procedures_ifreturn'].FUNCTION_TYPES.push(...customFunctionBlocks);

/* override the math_change block from the variables category to be less ugly */

Blockly.JavaScript['math_change'] = function(block) {
  // Add to a variable in place.
  var argument0 = Blockly.JavaScript.valueToCode(block, 'DELTA',
      Blockly.JavaScript.ORDER_ADDITION) || '0';
  var varName = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
  return varName + ' = ' + varName +
      ' + ' + argument0 + ';\n';
};

/* override for loop block */

Blockly.JavaScript['controls_for'] = function(block) {
  // For loop.
  var variable0 = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
  var argument0 = Blockly.JavaScript.valueToCode(block, 'FROM',
      Blockly.JavaScript.ORDER_ASSIGNMENT) || '0';
  var argument1 = Blockly.JavaScript.valueToCode(block, 'TO',
      Blockly.JavaScript.ORDER_ASSIGNMENT) || '0';
  var increment = Blockly.JavaScript.valueToCode(block, 'BY',
      Blockly.JavaScript.ORDER_ASSIGNMENT) || '1';
  var branch = Blockly.JavaScript.statementToCode(block, 'DO');
  branch = Blockly.JavaScript.addLoopTrap(branch, block);
  var code;
  if (Blockly.isNumber(argument0) && Blockly.isNumber(argument1) &&
      Blockly.isNumber(increment)) {
    // All arguments are simple numbers.
    var up = Number(argument0) <= Number(argument1);
    code = 'for (' + variable0 + ' = ' + argument0 + '; ' +
        variable0 + (up ? ' <= ' : ' >= ') + argument1 + '; ' +
        variable0;
    var step = Math.abs(Number(increment));
    if (step == 1) {
      code += up ? '++' : '--';
    } else {
      code += (up ? ' += ' : ' -= ') + step;
    }
    code += ') {\n' + branch + '}\n';
  } else {
    code = '';
    // Cache non-trivial values to variables to prevent repeated look-ups.
    var startVar = argument0;
    if (!argument0.match(/^\w+$/) && !Blockly.isNumber(argument0)) {
      startVar = Blockly.JavaScript.variableDB_.getDistinctName(
          variable0 + '_start', Blockly.VARIABLE_CATEGORY_NAME);
      code += 'var ' + startVar + ' = ' + argument0 + ';\n';
    }
    var endVar = argument1;
    if (!argument1.match(/^\w+$/) && !Blockly.isNumber(argument1)) {
      endVar = Blockly.JavaScript.variableDB_.getDistinctName(
          variable0 + '_end', Blockly.VARIABLE_CATEGORY_NAME);
      code += 'var ' + endVar + ' = ' + argument1 + ';\n';
    }
    var incVar = increment;
    if (!increment.match(/^\w+$/) && !Blockly.isNumber(increment)) {
      incVar = Blockly.JavaScript.variableDB_.getDistinctName(
          variable0 + '_end', Blockly.VARIABLE_CATEGORY_NAME);
      code += 'var ' + incVar + ' = ' + increment + ';\n';
    }
    code += 'for (' + variable0 + ' = ' + startVar + '; ' +
        variable0 + ' >= ' + endVar + '; ' +
        variable0 + ' += ' + incVar + ') {\n' +
        branch + '}\n';
  }
  return code;
};

/* add reserved variable names */
Blockly.JavaScript.addReservedWords('drone, sin, cos, tan, asin, acos, atan, await, droneEventListeners, keyPressListeners, keysPressed, startProgram, stopProgram, repeatForSeconds, consoleError, code');