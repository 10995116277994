import CodeMirror from "codemirror";


//creates a linting function for codemirror that wraps the default javascript linter 
//and adds any drone-specific errors to the returned list of errors in the following format:
//{from: CodeMirror.Pos, to: CodeMirror.Pos, message: String, severity: String(either 'warning' or 'error')}
export default function droneLinter(cmString, updateLinting, options, editor) {
    let js_errors = CodeMirror.lint.javascript(cmString, options);
    let drone_errors = findDroneErrors(cmString, editor);
    // console.log(js_errors.concat(drone_errors));
    let additional_drone_errors = astErrors(cmString, editor);
    // console.log(additional_drone_errors);
    drone_errors = additional_drone_errors.concat(drone_errors);
    updateLinting(js_errors.concat(drone_errors));
    return js_errors.concat(drone_errors);
}

//checks for errors in the provided string and returns them in the proper codemirror format
//errors are defined by a regex, and then the message and severity to report if they're found
function findDroneErrors(cmString, editor) {
    const possibleErrors = [
        //missing await before drone function call
        {
            re: /\w*(?<!await )drone\.(fly\(.*\)|rotate\(.*\)|takeOff\(\)|land\(\)|hover\(\)|cutoff\(\)|flip\(.*\)|waitUntilBatteryLevelChanges\(\)|wait\(.*\)|fireGun\(\)|takePicture\(\))/g,
            message: 'This function should be called with the await keyword - otherwise the program may skip over it\nExample:\n\tawait drone.takeOff();\n\n',
            severity: 'warning'
        },
        //missing await before repeatForSeconds function call
        {
            re: /\w*(?<!await )repeatForSeconds/g,
            message: 'This function should be called with the await keyword - otherwise the program may skip over it\nExample:\n\tawait drone.takeOff();\n\n',
            severity: 'error'
        },
        //invalid drone function/parameter
        {
            re: /drone\.(?!fly\(.*\)|rotate\(.*\)|takeOff\(\)|land\(\)|hover\(\)|cutoff\(\)|flip\(.*\)|grabber\(.*\)|waitUntilBatteryLevelChanges\(\)|wait\(.*\)|fireGun\(\)|takePicture\(\)|setAxis\(.*\)|isFlying\(\)|isLanded\(\)|getBatteryLevel\(\)|reset\(\))[\w]*(\W|$)/gm,
            message: 'Invalid drone function',
            severity: 'error'
        },
        //invalid drone event listener 
        {
            re: /droneEventListeners\.(?!flying([\W]|$)|landed([\W]|$)|crashed([\W]|$)|batteryLevelChanged([\W]|$))/gm,
            message: 'Invalid event listener, the options are:\n\tflying\n\tlanded\n\tcrashed\n\tbatteryLevelChanged',
            severity: 'error'
        },
        //invalid keypress event listener 
        {
            re: /keyPressListeners\.(?!pressed([\W]|$)|released([\W]|$))/gm,
            message: 'Invalid event listener, the options are:\n\tpressed\n\treleased',
            severity: 'error'
        },
        //invalid keypress event listener key
        {
            re: /keyPressListeners\.(pressed|released)\.(?!ArrowUp([\W]|$)|ArrowDown([\W]|$)|ArrowLeft([\W]|$)|ArrowRight([\W]|$)|w([\W]|$)|a([\W]|$)|s([\W]|$)|d([\W]|$)|b([\W]|$)|c([\W]|$)|e([\W]|$)|f([\W]|$)|g([\W]|$)|h([\W]|$)|i([\W]|$)|j([\W]|$)|k([\W]|$)|l([\W]|$)|m([\W]|$)|n([\W]|$)|o([\W]|$)|p([\W]|$)|q([\W]|$)|r([\W]|$)|t([\W]|$)|u([\W]|$)|v([\W]|$)|w([\W]|$)|x([\W]|$)|y([\W]|$)|z([\W]|$)|Space([\W]|$))/gm,
            message: 'Invalid key, the options are:\n\tArrowUp\n\tArrowDown\n\tArrowLeft\n\tArrowRight\n\tw\n\ta\n\ts\n\td\n\tSpace',
            severity: 'error'
        },
        //invalid keysPressed event listener key
        {
            re: /keysPressed\.(?!ArrowUp([\W]|$)|ArrowDown([\W]|$)|ArrowLeft([\W]|$)|ArrowRight([\W]|$)|w([\W]|$)|a([\W]|$)|s([\W]|$)|d([\W]|$)|b([\W]|$)|c([\W]|$)|e([\W]|$)|f([\W]|$)|g([\W]|$)|h([\W]|$)|i([\W]|$)|j([\W]|$)|k([\W]|$)|l([\W]|$)|m([\W]|$)|n([\W]|$)|o([\W]|$)|p([\W]|$)|q([\W]|$)|r([\W]|$)|t([\W]|$)|u([\W]|$)|v([\W]|$)|w([\W]|$)|x([\W]|$)|y([\W]|$)|z([\W]|$)|Space([\W]|$))/gm,
            message: 'Invalid key, the options are:\n\tArrowUp\n\tArrowDown\n\tArrowLeft\n\tArrowRight\n\tw\n\ta\n\ts\n\td\n\tSpace',
            severity: 'error'
        },

        //TOOLTIPS below
        {
            re: /await/g,
            message: 'await (JavaScript keyword)\nCan only be used inside an async function\nPauses execution until the function following it returns\n\nExample: "await drone.flip()" will tell the drone to do a flip, and not continue onto the next line of code until it\'s done flipping',
            severity: 'none'
        },
        {
            re: /drone.takeOff\(.*\)/g,
            message: 'drone.takeOff()\nNo parameters',
            severity: 'none'
        },
        {
            re: /drone.land\(.*\)/g,
            message: 'drone.land()\nNo parameters',
            severity: 'none'
        },
        {
            re: /drone.hover\(.*\)/g,
            message: 'drone.hover()\nNo parameters\n\nWill cancel any movement set by drone.setAxis(...)',
            severity: 'none'
        },
        {
            re: /drone.wait\(.*\)/g,
            message: 'drone.wait(time)\n1 Parameter:\n\ntime in seconds (Number)',
            severity: 'none'
        },
        {
            re: /drone.cutoff\(.*\)/g,
            message: 'drone.cutoff()\nNo parameters\n\nWill cut power to the drone - careful!',
            severity: 'none'
        },
        {
            re: /drone.fly\(.*\)/g,
            message: 'drone.fly(direction, seconds, power)\n3 parameters: \n\ndirection (String)\n\t"forward"\n\t"backward"\n\t"left"\n\t"right"\n\t"up"\n\t"down"\ntime in seconds (Number)\npower (Number)\n\t0 - 100',
            severity: 'none'
        },
        {
            re: /drone.setAxis\(.*\)/g,
            message: 'drone.setAxis(axis, power)\n2 parameters: \n\naxis (String)\n\t"roll"\n\t"pitch"\n\t"yaw"\n\t"altitude"\npower (Number)\n\t-100 - 100\n\nNote: this function does not need to be called with "await" - it sets the velocity along the given axis instantly and indefinitely',
            severity: 'none'
        },
        {
            re: /drone.rotate\(.*\)/g,
            message: 'drone.rotate(degrees, direction)\n2 parameters:\n\ndegrees (Number) \n\t-inf - inf\ndirection (String - optional)\n\t"clockwise" (default)\n\t"counterclockwise',
            severity: 'none'
        },
        {
            re: /drone.flip\(.*\)/g,
            message: 'drone.flip(direction)\n1 parameter:\ndirection (String - optional)\n\t"forward" (default)\n\t"backward"\n\t"left"\n\t"right"\n\t',
            severity: 'none'
        },
        {
            re: /drone.flip\(.*\)/g,
            message: 'drone.grabber(openOrClose)\n1 parameter:\nopenOrClose (String - optional)\n\t"OPEN" (default)\n\t"CLOSE"\\n\t',
            severity: 'none'
        },
        {
            re: /drone.fireGun\(.*\)/g,
            message: 'drone.fireGun()\nNo parameters\n\nBB gun must be attached',
            severity: 'none'
        },
        {
            re: /drone.takePicture\(.*\)/g,
            message: 'drone.takePicture()\nNo parameters',
            severity: 'none'
        },
        {
            re: /drone.getBatteryLevel\(.*\)/g,
            message: 'drone.getBatteryLevel()\nNo parameters\nReturns a Number (the drone\'s current battery level out of 100)',
            severity: 'none'
        },
        {
            re: /drone.isLanded\(.*\)/g,
            message: 'drone.isLanded()\nNo parameters\nReturns a Boolean\n\ttrue if the drone is landed\n\tfalse otherwise',
            severity: 'none'
        },
        {
            re: /drone.waitUntilBatteryLevelChanges\(.*\)/g,
            message: 'drone.waitUntilBatteryLevelChanges()\nNo parameters\n',
            severity: 'none'
        },
        {
            re: /drone.isFlying\(.*\)/g,
            message: 'drone.isFlying()\nNo parameters\nReturns a Boolean\n\ttrue if the drone is flying\n\tfalse otherwise',
            severity: 'none'
        },
        {
            re: /startProgram/g,
            message: 'startProgram is the "main" function that gets executed when the program is run.\n',
            severity: 'none'
        },
        {
            re: /stopProgram/g,
            message: 'stopProgram is an internal function that stops the program just as if you pressed the STOP button.\n',
            severity: 'none'
        },
        {
            re: /console.log\(/g,
            message: 'console.log() is used to print to the console. It can print a String or a variable.\nExample:\n\tconsole.log("Battery Level: ");\n\tconsole.log(drone.getBatteryLevel());',
            severity: 'none'
        },
        {
            re: /keyPressListeners/g,
            message: 'keyPressListeners (Object)\nStores definitions of functions that will be called when the associated key is pressed/released\n2 properties: "pressed" and "released", \n\teach with subproperties: \n\t"ArrowUp", "ArrowDown", \n\t"ArrowLeft", "ArrowRight", \n\t"Space", "a", "b", "c" ... "y", "z" ',
            severity: 'none'
        },
        {
            re: /keysPressed/g,
            message: 'keysPressed (Object)\nStores a Boolean value for each key - true if the key is pressed, false if it is released\n9 properties: \n\t"ArrowUp", "ArrowDown", \n\t"ArrowLeft", "ArrowRight", \n\t"Space", "a", "b", "c" ... "y", "z"',
            severity: 'none'
        },
        {
            re: /droneEventListeners/g,
            message: 'droneEventListeners (Object)\nStores definitions of functions that will be called when the drone\'s state changes\n4 properties: "flying", "landed", "crashed", "batteryLevelChanged"',
            severity: 'none'
        },
        {
            re: /(!?\W)(sin|cos|tan)\(/g,
            message: 'Trigonometry Function \n1 Parameter: \n\t Angle in degrees (Number)',
            severity: 'none'
        },
        {
            re: /(asin|acos|atan)\(/g,
            message: 'Inverse trigonometry Function \nReturns angle in degrees (Number)',
            severity: 'none'
        },
        {
            re: /repeatForSeconds/g,
            message: 'function(seconds, callback function)\nruns the code in the callback function as many times as it can within the allotted amount of seconds',
            severity: 'none'
        }
        
    ];
    let allErrors = [];

    //check the editor for each error
    possibleErrors.forEach(error => {

        let result = checkForError(editor, cmString, error);
        if ( result.length >0 ) {
            //unpacks the array of errors that were found and adds them into allErrors
            allErrors.push(...result);
        }
    });
        
    // allErrors.push(...checkForBadArgs(editor));

    return allErrors;
}

//checks the editor string for a specific type of error (defined by a regex)
//returns false if the error was not found in the editor string
//returns an array of the cm-formatted errors if any were found
function checkForError(editor, cmString, error) {
    let allErrors = [];

    let re = error.re;
    let str = cmString;
    //use regex.exec to find all instances of the error
    let match;
    while ((match = re.exec(str)) != null){
        //grab the start and end of the error instance, then convert them to codemirror Pos objects
        let start = match.index;
        let end = match.index + match[0].length;
        let from = editor.posFromIndex(start);
        let to = editor.posFromIndex(end);

        //make sure the error isn't inside of a comment
        if ( editor.getTokenAt(from).type == 'comment') continue;

        //construct the error object as required for a codemirror linting function
        let cmError = {
            from: from,
            to: to,
            message: error.message,
            severity: error.severity
        }
        // console.log(cmError);
        allErrors.push(cmError);

    }
    return allErrors;
}

//use esprima to find additional errors that are too complicated for regex
function astErrors (text, editor ) {
    let ast;
    try {
        // eslint-disable-next-line no-undef
        ast = esprima.parse(text, {range: true});
    } catch (e) {
        return [];
    }
    let allErrors = visitNode(ast);

    allErrors.forEach(error => {
        error.from = editor.posFromIndex(error.start);
        error.to = editor.posFromIndex(error.end);
    })
    return allErrors;
}

function visitNode (node, parent) {

    let allErrors = checkNodeForErrors(node, parent);
    for (const key in node) {
        let child = node[key];
        if (Array.isArray(child)) {
            for(let i=0;i<child.length;i++) {
                allErrors.push(...visitNode(child[i], node));
            }
        } else if (typeof child == 'object' && child != null) {
            allErrors.push(...visitNode(child, node));
        }
    }
    return allErrors;
}

function checkNodeForErrors (node, parent) {
    let argInfo = {
        fly: {num: [3]},
        rotate: {num: [1,2]},
        flip: {num: [0,1]},
        setAxis: {num: [2]},
        wait: {num: [1]},
    };
    let allErrors = [];
    if (node && node.type == 'CallExpression' && node.callee.type == 'MemberExpression') {
        let funcName = node.callee.property.name;
        //check if drone functions are being passed the proper number of arguments
        if (node.callee.object.name == 'drone' && funcName in argInfo) {
            let numArgs = argInfo[funcName].num
            if (!numArgs.includes(node.arguments.length)) {
                let errorMsg = '';
                if (numArgs.length == 1) errorMsg += numArgs[0];
                else {
                    errorMsg = numArgs.join(' or ');
                }
                allErrors.push(formatNodeError(node, 'drone.'+funcName+' takes '+errorMsg+' arguments', 'error'));
            }
        }

    }
    if (parent && parent.type == 'oop') {
        return;
    }
    return allErrors;
}

function formatNodeError(node, message, severity) {
    let start = node.range[0];
    let end = node.range[1];
    return {start: start, end: end, message: message, severity: severity};
}

// function checkForBadArgs (editor) {
//     const allFunctions = [
//         {
//             re: /drone.flip\((.*)\)/gm,
//             numArgs: [1, 0],
//         },
//         {
//             re: /drone.fly\((.*)\)/gm,
//             numArgs: [3]
//         },
//         {
//             re: /drone.setAxis\((.*)\)/gm,
//             numArgs: [2]
//         },
//         {
//             re: /drone.wait\((.*)\)/gm,
//             numArgs: [1]
//         },
//         {
//             re: /drone.setAxis\((.*)\)/gm,
//             numArgs: [2]
//         },
//         {
//             re: /drone.rotate\((.*)\)/gm,
//             numArgs: [1, 2]
//         },
//     ]
//     let allErrors = [];
//     allFunctions.forEach(func => {
//         let str = editor.getValue();
//         let match;

//         while ((match = func.re.exec(str)) != null){
//             let start = match.index;
//             let end = start+match[0].length;
//             let from = editor.posFromIndex(start), to = editor.posFromIndex(end);

//             let args = match[1];
//             args = args.split(', ');
//             args = args.filter(arg => arg.length > 0);
//             // console.log('arges', args);
//             if(!func.numArgs.includes(args.length)) {
//                 // console.log(args);
//                 allErrors.push({
//                     message: 'Function takes '+func.numArgs+' parameter(s)',
//                     severity: 'error',
//                     from: from,
//                     to: to
//                 });
//             } 
//         }
//     });

//     return allErrors;
// }