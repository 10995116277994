<template>
  <div id="consoleDiv">
    <div id='outputDiv'>
      <div id='outputInnerDiv'>
        <p v-for="(message, index) in outputLog" :key="index" class='consoleMessage'>{{formatMessage(message)}}</p>
        <div v-if="outputLog === undefined || outputLog.length == 0" class='consoleEmpty'>
          No program output to show. Use the "Print" block under "General" to start debugging your program.
        </div>
      </div>
    </div>
    <div id='clearConsoleButtonWrapper'>
      <button @click="$emit('clear-console')">Clear Console</button>
    </div>
    
  </div>
</template>

<script>
import { FlightError } from '../drone'
export default {
    name: 'OutputConsole',
    props: {
      outputLog: Array,
    },
    data () {
        return {

        }
    },
    methods : {
      formatMessage (message) {
        if (message instanceof FlightError) {
          message = message.message;
        } 
        return "> " + message;
      } 
    }
}
</script>

<style>
  #consoleDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    
  }
  #outputDiv {
    flex: 1 1 auto;
    overflow: auto;
    padding: 0px 12px;
    display: flex;
    flex-direction: column-reverse;
  }
  #outputInnerDiv {
    flex: 1 0 auto;
  }
  #clearConsoleButtonWrapper {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    
    padding: 12px;

  }
  #clearConsoleButtonWrapper button {
    background-color: #242323;
    color: white;
    font-size: 18px;
    padding: 12px 24px;
    width: 100%;
    border: none;
    border-radius: 1px;
  }
  .consoleMessage {
    border: none;
    border-bottom: 1px solid #dbdbdb;
    margin: none;
    padding: 6px 0px;
  }
  .consoleEmpty {
    padding: 24px 12px;
  }
</style>