/* eslint-disable */

export default function codeToBlockXml (code) {
    // eslint-disable-next-line no-undef
    let ast = esprima.parseScript(code, {attachComment: true});
    
    window.functionInfo = getFunctionInfo(ast);
    window.varsDefined = [];
    let generatedXml = astToXml(ast);
    generatedXml = addVarsToXml(generatedXml, window.varsDefined);
    return generatedXml;
}


function astToXml(node, stack) {

    let nodeCallbacks = {
        Program: function(node) {
            let initXml = '<xml xmlns="https://developers.google.com/blockly/xml">';
            node.body.forEach(childNode => {
                initXml += astToXml(childNode);
            });
    
            let finishedXml = initXml+ '</xml>';
            return finishedXml;
        },
        IfStatement: function(node, stack) {
            //procedures_ifreturn block and continue_if block
            if (node.consequent.type=='ReturnStatement' || node.consequent.body.length == 1 && node.consequent.body[0].type == 'ReturnStatement') {
                //check if code can be converted to continue_if block
                if (node.test.type == 'UnaryExpression' && node.test.operator == '!') {
                    let conditionXml = '<value name="condition">' + astToXml(node.test.argument) + '</value>';
                    let next = unpackNextStack(stack);
                    return '<block type="continue_if">'+conditionXml+ next+'</block>';
                }
                let conditionXml = '<value name="CONDITION">' + astToXml(node.test) + '</value>';
                let returnNode;
                if(node.consequent.type=='ReturnStatement') returnNode = node.consequent.argument;
                else returnNode = node.consequent.body[0].argument;
                let returnValueXml = '<value name="VALUE">' + astToXml(returnNode) + '</value>';
                let next = unpackNextStack(stack);
                return '<block type="procedures_ifreturn">'+conditionXml+returnValueXml + next+'</block>';
            }
            //normal if statement blocks below

            //open the block
            let xml = '<block type="controls_if">';
            //add a mutation to the block if there is an else statement
            if (node.alternate) {
                xml += '<mutation else="1"/>'
            }
            //add the test condition 
            xml += '<value name="IF0">' + astToXml(node.test) + '</value>';
        
            //add the blocks to execute if true
            xml += '<statement name="DO0">' + astToXml(node.consequent) + '</statement>';
            //check for else statement and add contents if it exists
            if (node.alternate) {
                xml += '<statement name="ELSE">' + astToXml(node.alternate) + '</statement>';
            }
            xml += unpackNextStack(stack);
            //close the block
            xml += '</block>'
            return xml;
        },
        BlockStatement: function(node) {
            //just visit any code inside the block statement
            if (node.body.length == 0) return '';
            let blockStack = node.body.slice(1);
            let xml = astToXml(node.body[0], blockStack);
            return xml;
        },
        Literal: function(node) {
            if (typeof node.value == 'boolean') {
                let value = node.value? 'TRUE' : 'FALSE';
                return '<block type="logic_boolean"><field name="BOOL">'+value+'</field></block>';
            }
            if (node.value === null) {
                return '<block type="logic_null"/>'
            }
            if (typeof node.value == 'number') {
                return '<shadow type="math_number"><field name="NUM">'+node.value+'</field></shadow>';
            }
        },
        ExpressionStatement: function(node, stack) {
            return astToXml(node.expression, stack);
        },
        AwaitExpression: function(node, stack) {
            return astToXml(node.argument, stack);
        },
        UnaryExpression: function(node) {
            if (node.operator == '!') {
                //TODO: check that types match?
                return '<block type="logic_negate"><value name="BOOL">'+astToXml(node.argument)+'</value></block>';
            }
            if (node.operator == '-') {
                //TODO: check that types match?
                return '<block type="math_single"><field name="OP">NEG</field><value name="NUM">'+astToXml(node.argument)+'</value></block>';
            }
        },
        BinaryExpression: function(node) {
            //comparison operators
            if (['==','===','!=','<','>','<=','>='].includes(node.operator)) {
                
                //convert the javascript operator to blockly's enumeration
                let ops = {'==': 'EQ', '===': 'EQ', '!=': 'NEQ', '<': 'LT', '<=': 'LTE', '>': 'GT', '>=': 'GTE'}
                let op = ops[node.operator];
                //parse the left and right operands
                let left = astToXml(node.left);
                let right = astToXml(node.right);
                return '<block type="logic_compare"><field name="OP">'+op+'</field><value name="A">'+left+'</value><value name="B">'+right+'</value></block>'
            }
            if (['|','&'].includes(node.operator)) {
                //convert the javascript operator to blockly's enumeration
                let ops = {'|': 'OR', '&': 'AND'};
                let op = ops[node.operator];
                //parse the left and right operands
                let left = astToXml(node.left);
                let right = astToXml(node.right);
                return '<block type="logic_operation"><field name="OP">'+op+'</field><value name="A">'+left+'</value><value name="B">'+right+'</value></block>'
            }
            //arithmetic operations
            if (['+','-','*','/','**'].includes(node.operator)) {
                //convert the javascript operator to blockly's enumeration
                let ops = {'+': 'ADD', '-': 'MINUS', '*': 'MULTIPLY', '/': 'DIVIDE', '**': 'POWER'};
                let op = ops[node.operator];
                //parse the left and right operands
                let left = astToXml(node.left);
                let right = astToXml(node.right);
                return '<block type="math_arithmetic"><field name="OP">'+op+'</field><value name="A">'+left+'</value><value name="B">'+right+'</value></block>'
            }
            //modulo operator
            if (node.operator == '%') {
                let left = astToXml(node.left);
                let right = astToXml(node.right);
                return '<block type="math_modulo"><value name="DIVIDEND">'+left+'</value><value name="DIVISOR">'+right+'</value></block>'
            }
            
        },
        LogicalExpression: function(node) {
            if (['||','&&'].includes(node.operator)) {
                //convert the javascript operator to blockly's enumeration
                let ops = {'||': 'OR', '&&': 'AND'};
                let op = ops[node.operator];
                //parse the left and right operands
                let left = astToXml(node.left);
                let right = astToXml(node.right);
                return '<block type="logic_operation"><field name="OP">'+op+'</field><value name="A">'+left+'</value><value name="B">'+right+'</value></block>'
            }
        },
        CallExpression: function(node, stack) {
            //user-defined function
            if(node.callee.name in window.functionInfo) {
                let funcName = node.callee.name;

                //determine if function has a return value
                let type;
                if (window.functionInfo[funcName].hasReturn) {
                    type = 'procedures_callreturn';
                } else {
                    type = 'procedures_callnoreturn';
                }

                //translate function name and params into mutation info
                let params = window.functionInfo[funcName].params;
                let mutationXml = '<mutation name="'+funcName+'">';
                params.forEach(param => {
                    mutationXml += '<arg name="'+param+'"/>';
                });
                mutationXml += '</mutation>';

                let argsXml = '';
                let args = node.arguments;
                for (let i=0; i< args.length; i++) {
                    let value = astToXml(args[i]);
                    argsXml += '<value name="ARG'+i+'">'+value+'</value>';
                }
                let next = unpackNextStack(stack);

                return '<block type="'+type+'">'+mutationXml+argsXml+next+'</block>';

            }
            //stopProgram
            if (node.callee.name == 'stopProgram') {
                return '<block type="stop_program"></block>';
            }
            //Trig functions
            if (['sin','cos','tan','asin','acos','atan'].includes(node.callee.name)) {
                let op = node.callee.name.toUpperCase();
                let arg = astToXml(node.arguments[0]);
                return '<block type="math_trig"><field name="OP">'+op+'</field><value name="NUM">'+arg+'</value></block>';
            }
            //repeatForSeconds function
            if (node.callee.name == 'repeatForSeconds') {
                let seconds = astToXml(node.arguments[0]);
                let body = astToXml(node.arguments[1].body);
                let next = unpackNextStack(stack);
                return '<block type="repeat_seconds"><value name="seconds">'+seconds+'</value><statement name="do">'+body+'</statement>'+next+'</block>';
            }
            //console.log()
            if (node.callee.object.name == 'console' && node.callee.property.name == 'log') {
                let str = '';
                let arg = '';
                if(node.arguments[0] && node.arguments[0].type == 'Literal' && typeof node.arguments[0].value == 'string') {
                    str = node.arguments[0].value;
                } else {
                    arg = astToXml(node.arguments[0]);
                }
                if (node.arguments.length > 1) {
                    node.arguments = node.arguments.slice(1);
                    stack.unshift(node);
                }
                let next = unpackNextStack(stack);
                return '<block type="custom_print"><value name="message"><shadow type="text"><field name="TEXT">'+str+'</field></shadow>'+arg+'</value>'+next+'</block>';
            }
            //Math functions with single arg
            if (node.callee.object.name == 'Math' && ['sqrt', 'abs', 'log', 'exp'].includes(node.callee.property.name)) {
                //convert the javascript operator to blockly's enumeration
                let ops = {'sqrt': 'ROOT', 'abs': 'ABS', 'log': 'LN', 'exp': 'EXP'};
                let op = ops[node.callee.property.name];
                //parse the argument to the function
                let arg = astToXml(node.arguments[0]);
                return '<block type="math_single"><field name="OP">'+op+'</field><value name="NUM">'+arg+'</value></block>'
            }
            //Power function
            if (node.callee.object.name == 'Math' && node.callee.property.name == 'pow') {
                let left = astToXml(node.arguments[0]);
                let right = astToXml(node.arguments[1]);
                return '<block type="math_arithmetic"><field name="OP">POWER</field><value name="A">'+left+'</value><value name="B">'+right+'</value></block>'
            }
            //Math.round
            if (node.callee.object.name == 'Math' && ['round', 'ceil', 'floor'].includes(node.callee.property.name)) {
                //convert the javascript operator to blockly's enumeration
                let ops = {'round': 'ROUND', 'ceil': 'ROUNDUP', 'floor': 'ROUNDDOWN'};
                let op = ops[node.callee.property.name];
                //parse the argument to the function
                let arg = astToXml(node.arguments[0]);
                return '<block type="math_round"><field name="OP">'+op+'</field><value name="NUM">'+arg+'</value></block>'
            }
            //Random
            if (node.callee.object.name == 'Math' && node.callee.property.name == 'random') {
                return '<block type="math_random_float"/>';
            }

            /*      drone functions     */

            //0 parameter functions
            if (node.callee.object.name == 'drone' && ['takeOff','land','hover','cutoff','fireGun','takePicture','waitUntilBatteryLevelChanges','getBatteryLevel','reset'].includes(node.callee.property.name)) {
                let blockNames = {'takeOff':'takeoff','land':'land','hover':'hover','cutoff':'cutoff','fireGun':'fire_bb','takePicture':'take_picture','waitUntilBatteryLevelChanges':'wait_until_battery_changes','getBatteryLevel': 'get_battery_level','reset':'reset'};
                let blockName = 'minidrone_' + blockNames[node.callee.property.name];
                let next = unpackNextStack(stack);
                return '<block type="'+blockName+'">'+next+'</block>';
            }
            //drone.fly()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'fly') {
                let direction = node.arguments[0].value;
                let time = astToXml(node.arguments[1]) || 0;
                let power = astToXml(node.arguments[2]) || 0;
                let next = unpackNextStack(stack);
                return '<block type="minidrone_fly"><field name="direction">'+direction+'</field><value name="seconds">'+time+'</value><value name="power">'+power+'</value>'+next+'</block>'
            }
            //drone.rotate()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'rotate') {
                let degrees = astToXml(node.arguments[0]) || 0;
                let direction = node.arguments[1].value;
                let next = unpackNextStack(stack);
                return '<block type="minidrone_rotate"><field name="direction">'+direction+'</field><value name="DEGREES">'+degrees+'</value>'+next+'</block>'
            }
            //drone.wait()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'wait') {
                let seconds = astToXml(node.arguments[0]) || 0;
                let next = unpackNextStack(stack);
                return '<block type="wait"><value name="seconds">'+seconds+'</value>'+next+'</block>'
            }
            //drone.setAxis()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'setAxis') {
                let power = astToXml(node.arguments[1]) || 0;
                let direction = node.arguments[0].value;
                let next = unpackNextStack(stack);
                return '<block type="minidrone_set_direction"><field name="axis">'+direction+'</field><value name="NAME">'+power+'</value>'+next+'</block>'
            }
            //drone.flip()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'flip') {
                let direction;
                if (node.arguments[0]) direction = node.arguments[0].value;
                else direction = 'forward';
                let next = unpackNextStack(stack);
                return '<block type="minidrone_flip"><field name="direction">'+direction+'</field>'+next+'</block>'
            }
            //drone.isLanded() + drone.isFlying
            if (node.callee.object.name == 'drone' && (node.callee.property.name == 'isLanded' || node.callee.property.name == 'isFlying')) {
                let states = {'isLanded': 'landed', 'isFlying': 'flying'};
                let state = states[node.callee.property.name];
                return '<block type="minidrone_flying_state"><field name="flying_state">'+state+'</field></block>'
            }
            //drone.grabber()
            if (node.callee.object.name == 'drone' && node.callee.property.name == 'grabber') {
                let openOrClose;
                if (node.arguments[0]) openOrClose = node.arguments[0].value;
                else openOrClose = 'OPEN';
                console.log('ooc', openOrClose)
                let next = unpackNextStack(stack);
                return '<block type="minidrone_grabber"><field name="openOrClose">'+openOrClose+'</field>'+next+'</block>'
            }
        },
        MemberExpression: function(node) {
            //Math constants
            if (node.object.name == 'Math' && ['PI','E','SQRT2','SQRT1_2'].includes(node.property.name)) {
                let op = node.property.name;
                return '<block type="math_constant"><field name="CONSTANT">'+op+'</field></block>';
            }
            //keysPressed
            if (node.object.name == 'keysPressed' && ['ArrowUp','ArrowDown','ArrowLeft','ArrowRight','Space','w','a','s','d','b','c','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','x','y','z'].includes(node.property.name)) {
                let key = node.property.name;
                return '<block type="is_key_pressed"><field name="key">'+key+'</field><field name="pressed_or_released">pressed</field></block>'
            }
        },
        Identifier: function(node) {
            //Infinity
            if (node.name == 'Infinity') {
                return '<block type="math_constant"><field name="CONSTANT">INFINITY</field></block>';
            }
            if (window.varsDefined.includes(node.name)) {
                return '<block type="variables_get"><field name="VAR">'+node.name+'</field></block>';
            }
        },
        AssignmentExpression: function(node) {
            //startProgram 
            if (node.left.name == 'startProgram') {
                let body = astToXml(node.right.body);
                return '<block type="program_start"><statement name="main_code">'+body+'</statement></block>';
            }
            //keypressListeners
            if (node.left.type == 'MemberExpression' && node.left.object.type=='MemberExpression' && node.left.object.object.name == 'keyPressListeners') {
                let key = node.left.property.name;
                let pressedOrReleased = node.left.object.property.name;
                let body = astToXml(node.right.body);
                return '<block type="event_when_key_pressed"><field name="key">'+key+'</field><field name="pressed_or_released">'+pressedOrReleased+'</field><statement name="do">'+body+'</statement></block>'
            }
            //drone events
            if (node.left.type == 'MemberExpression' && node.left.object.name == 'droneEventListeners') {
                if (['flying', 'landed'].includes(node.left.property.name)) {
                    let state = node.left.property.name;
                    let body = astToXml(node.right.body);
                    return '<block type="event_when_minidrone_flying_state"><field name="STATE">'+state+'</field><statement name="DO">'+body+'</statement></block>'
                }
                if (node.left.property.name=='crashed') {
                    let body = astToXml(node.right.body);
                    return '<block type="event_when_minidrone_crashes"><statement name="DO">'+body+'</statement></block>'
                }
                if (node.left.property.name=='batteryLevelChanged') {
                    let body = astToXml(node.right.body);
                    return '<block type="event_when_minidrone_battery_level_changes"><statement name="DO">'+body+'</statement></block>'
                }
            }
            //+=, -=, etc. 
            if (varsDefined.includes(node.left.name) && ['+=','-=','*=','/='].includes(node.operator)) {
                let value = astToXml(node.right);
                let next = unpackNextStack(stack);
                let left = astToXml(node.left);
                //convert the javascript operator to blockly's enumeration
                let ops = {'+': 'ADD', '-': 'MINUS', '*': 'MULTIPLY', '/': 'DIVIDE'};
                let op = ops[node.operator.slice(0,1)];
                return '<block type="variables_set"><field name="VAR">'+node.left.name+'</field><value name="VALUE"><block type="math_arithmetic"><field name="OP">'+op+'</field><value name="A">'+left+'</value><value name="B">'+value+'</value></block></value>'+next+'</block>';
            }
            //change var by _ block
            if (window.varsDefined.includes(node.left.name) && node.right.type == 'BinaryExpression' && node.right.operator == '+' && node.right.left.name == node.left.name) {
                let value = astToXml(node.right.right);
                let next = unpackNextStack(stack);
                return '<block type="math_change"><field name="VAR">'+node.left.name+'</field><value name="DELTA">'+value+'</value>'+next+'</block>';
            }
            //user-defined variable assignment
            if (window.varsDefined.includes(node.left.name)) {
                let value = astToXml(node.right);
                let next = unpackNextStack(stack);
                return '<block type="variables_set"><field name="VAR">'+node.left.name+'</field><value name="VALUE">'+value+'</value>'+next+'</block>';
            }
        },
        FunctionDeclaration: function(node) {
            //determine whether the function has a return value
            let type;
            let returnXml = '';
            if (functionHasReturn(node)) {
                type = 'procedures_defreturn';
                let returnNode = getReturnNode(node);
                returnXml = '<value name="RETURN">'+astToXml(returnNode.argument)+'</value>';
            } else {
                type = 'procedures_defnoreturn';
            }

            //grab the params 
            let args = node.params.map(param => param.name);
            let argsXml = '';
            if (args.length > 0) {
                argsXml += '<mutation>';
                args.forEach(arg => {
                    argsXml += '<arg name="'+arg+'"/>';
                });
                argsXml += '</mutation>';
            }

            let funcName = node.id.name;
            let body = astToXml(node.body);
            return '<block type="'+type+'">'+argsXml+'<field name="NAME">'+funcName+'</field>'+returnXml+'<statement name="STACK">'+body+'</statement></block>';

        },
        WhileStatement: function (node, stack) {
            //repeat forever block 
            if(node.test.type == 'Literal' && node.test.value == true) {
                let body = astToXml(node.body);
                let next = unpackNextStack(stack);
                return '<block type="repeat_forever"><statement name="block_of_code">'+body+'</statement>'+next+'</block>';
            }
            
            //normal while loop:
            let test, mode;
            //check if 'until' block can be used
            if (node.test.type == 'UnaryExpression' && node.test.operator == '!') {
                test = astToXml(node.test.argument);
                mode = 'UNTIL';
            } else {
                test = astToXml(node.test);
                mode = 'WHILE';
            }
            let body = astToXml(node.body);
            let next = unpackNextStack(stack);
            return '<block type="controls_whileUntil"><field name="MODE">'+mode+'</field><value name="BOOL">'+test+'</value><statement name="DO">'+body+'</statement>'+next+'</block>';
        },
        BreakStatement: function (node) {
            return '<block type="controls_flow_statements"><field name="FLOW">BREAK</field></block>';
        },
        ContinueStatement: function (node) {
            return '<block type="controls_flow_statements"><field name="FLOW">CONTINUE</field></block>';
        },
        VariableDeclaration: function(node, stack) {
            let xml = '';
            node.declarations.forEach(declaration => {
                xml += astToXml(declaration, stack);
            });
            return xml;
        },
        VariableDeclarator: function(node, stack) {
            //add the variable to the global list to register it in blockly
            varsDefined.push(node.id.name);
            if (node.init) {
                let value = astToXml(node.init);
                let next = unpackNextStack(stack);
                return '<block type="variables_set"><field name="VAR">'+node.id.name+'</field><value name="VALUE">'+value+'</value>'+next+'</block>';
            } else return '';
        },
        ForStatement: function(node, stack) {
            //attempt to match "repeat _ times" block
            if (node.init.type == 'VariableDeclaration' && node.init.declarations[0].init.value == 0) {
                let counterName = node.init.declarations[0].id.name;
                let countTo = astToXml(node.test.right);
                let next = unpackNextStack(stack);
                let body = astToXml(node.body);
                return '<block type="controls_repeat_ext"><value name="TIMES">'+countTo+'</value><statement name="DO">'+body+'</statement>'+next+'</block>';
            }
            //normal for loop
            let varName = node.init.left.name;
            let initVal = astToXml(node.init.right);
            let endVal = astToXml(node.test.right);
            //handle all sorts of update expressions
            let incrementVal;
            if(node.update.type == 'UpdateExpression') {
                if (node.update.operator == '++') {
                    incrementVal = '<shadow type="math_number"><field name="NUM">1</field></shadow>';
                }
                if (node.update.operator == '--') {
                    incrementVal = '<shadow type="math_number"><field name="NUM">-1</field></shadow>';
                }
                
            } else if (node.update.type == 'AssignmentExpression') {
                if (node.update.operator == '+=') {
                    incrementVal = astToXml(node.update.right);
                }
                if (node.update.operator == '-=') {
                    incrementVal = '<block type="math_single"><field name="OP">NEG</field><value name="NUM">'+astToXml(node.update.right)+'</value></block>'
                }
                if (node.update.operator == '=') {
                    if(node.update.right.left.type == 'Identifier') {
                        incrementVal = astToXml(node.update.right.right);
                        if(node.update.right.operator == '-') {
                            incrementVal = '<block type="math_single"><field name="OP">NEG</field><value name="NUM">'+incrementVal+'</value></block>'
                        }
                    } else {
                        incrementVal = astToXml(node.update.right.left);
                    }
                }
            }
            let next = unpackNextStack(stack);
            let body = astToXml(node.body);
            return '<block type="controls_for"><field name="VAR">'+varName+'</field><value name="FROM">'+initVal+'</value><value name="TO">'+endVal+'</value><value name="BY">'+incrementVal+'</value><statement name="DO">'+body+'</statement>'+next+'</block>';
        }
    }
    if (node && node.type && node.type in nodeCallbacks) {
        let blockDefinition = nodeCallbacks[node.type](node, stack);
        //check if there's any comments to be attached to the block
        if(node.leadingComments) {
            //concat all leading comments into one
            let fullComment = '';
            node.leadingComments.forEach(comment=>fullComment += comment.value.trim()+'\n');
            fullComment = fullComment.trim();
            //add comment(s) to the block definition
            blockDefinition = addCommentToBlock(blockDefinition, fullComment);
        }
        return blockDefinition;
    } else {
        return '';
        // return '<' +node.type + '>';
    }
    
}

function unpackNextStack(stack) {
    if (stack && stack.length > 0) {
        
        let nextNode = stack.shift();
        let nextBlock = astToXml(nextNode, stack);
        return '<next>'+nextBlock+'</next>'
    } else {
        return '';
    }
}

function getFunctionInfo(ast) {
    let functionInfo = {}
    ast.body.forEach(node => {
        if ( node.type == 'FunctionDeclaration' ) {
            let name = node.id.name;
            let params = node.params.map(param => param.name);
            let hasReturn = functionHasReturn(node);
            functionInfo[name] = {'params':params, 'hasReturn':hasReturn}
        }
    });
    return functionInfo;
}

function functionHasReturn (node) {
    let has = false;
    node.body.body.forEach(child => {
        if ( child.type == 'ReturnStatement' ) has = true;
    });
    return has;
}

function getReturnNode (node) {
    let returnNode = null;
    node.body.body.forEach(child => {
        if ( child.type == 'ReturnStatement' ) returnNode = child;
    });
    return returnNode;
}

function addCommentToBlock (blockDefinition, comment) {
    //determine where the first block tag ends
    let insertIndex = blockDefinition.indexOf('><')+1;
    //wrap the comment in xml tags
    let commentXml = '<comment>'+comment+'</comment>';
    //insert the comment def into the block def
    let updatedBlock = blockDefinition.substring(0, insertIndex) + commentXml + blockDefinition.substring(insertIndex);
    //return the block def with comment added
    return updatedBlock;
}

function addVarsToXml(xml, varDefs) {
    //determine where the first xml tag ends
    let insertIndex = xml.indexOf('><')+1;
    //generate the variable definitions 
    let varXml = '<variables>';
    varDefs.forEach(varDef => {
        varXml += '<variable>'+varDef+'</variable>';
    });
    varXml += '</variables>';
    //insert variable defs into xml
    let updatedXml = xml.substring(0, insertIndex) + varXml + xml.substring(insertIndex);
    return updatedXml;
}