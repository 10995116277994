const log = console.log;

function arraysEqual(a1, a2) {
  //helper function for checking what command was recieved from the drone
  if (a1.length != a2.length) return false;
  for (let i = 0; i < a1.length; ++i) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
}

class FlightError extends Error {
  constructor(message) {
    super(message);
    this.name = "FlightError";
  }
}

class MamboController {
  constructor(blueToothDevice) {
    this.droneBluetoothDevice = blueToothDevice;
    this.gattServer = null;
    //these sequence numbers are used to identify individual commands sent to the drone(per characteristic);
    //each time they are used they are incremented - until they reach 255, then they're reset to 0
    this.seq = {
      fa0a: 0,
      fa0b: 0,
      fa0c: 0,
    };
    this.droneName = null;
    this.connectionPing = null;
    this.batteryLevel = null;
    this.flyingState = null;
    this.flyingOrLanded = "landed";
    this.cancelRunFlag = false;
    this.gunUSBID = null;
    this.gunAttached = false;
    this.clawUSBID = null;
    this.clawAttached = false;
    this.flightCommandPing = null;
    this.flightCommandBuffer = {
      roll: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      pitch: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      yaw: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      gaz: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
    };
  }

  async connect() {
    //Connect to the GATT server of the drone's BLE Device
    log("getting gatt server from drone...");
    this.gattServer = await this.droneBluetoothDevice.gatt.connect();

    this.droneName = this.droneBluetoothDevice.name;
    log("> Name:             " + this.droneName);
    log("> Id:               " + this.droneBluetoothDevice.id);
    log("> Connected:        " + this.droneBluetoothDevice.gatt.connected);

    //A list of the characteristics (listed as serviceID, charID) on which we must listen for notifications
    //These are all characteristics that the drone sends data over
    let characteristicsRequiringNotificationsForMambo = [
      ["fb00", "fb0e"],
      ["fb00", "fb0f"],
      ["fb00", "fb1b"],
      ["fb00", "fb1c"],
      ["fd21", "fd22"],
      ["fd21", "fd23"],
      ["fd51", "fd52"],
      ["fd51", "fd53"],
    ];

    let characteristicsRequiringNotificationsForHopper = [
      ["fb00", "fb0e"],
      ["fb00", "fb0f"],
      ["fb00", "fb1b"],
      ["fb00", "fb1c"],
    ];
    //Start notifications for each one
    if (
      this.droneName.startsWith("Mambo_") ||
      this.droneName.startsWith("mambo_") ||
      this.droneName.startsWith("MAMBO_") ||
      this.droneName.startsWith("TRAVIS_") ||
      this.droneName.startsWith("Travis_") ||
      this.droneName.startsWith("travis_") ||
      this.droneName.startsWith("FTW_") ||
      this.droneName.startsWith("ftw_") ||
      this.droneName.startsWith("Mars_")
    ) {
      for (const char of characteristicsRequiringNotificationsForMambo) {
        let serviceID = char[0];
        let charID = char[1];
        await this._startNotifications(serviceID, charID);
      }
    } else {
      for (const char of characteristicsRequiringNotificationsForHopper) {
        let serviceID = char[0];
        let charID = char[1];
        await this._startNotifications(serviceID, charID);
      }
    }

    //set the max rotation speed to 180 degrees per second
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      1,
      1,
      0,
      0,
      0,
      0x34,
      0x43,
    ]);
    //request all states to initialize battery and flying state info
    await this._writeCommand("fa00", "fa0a", [
      2,
      this.seq.fa0a++ % 255,
      0,
      4,
      0,
      0,
    ]);

    //Start ping, which is a function that sends data to the drone at a regular interval,
    //in order to remain connected. Otherwise the drone disconnects a few seconds after starting notifications
    this._startConnectionPing();
  }

  disconnect() {
    log("disconnecting...");
    this._stopConnectionPing();
    this._stopFlightPing();
    if (this.gattServer) {
      this.gattServer.disconnect();
      this.gattServer = null;
    }
  }

  async startRun() {
    log("start run...");
    this._stopConnectionPing();
    this._startFlightPing();
    // await this.hover();
    this._resetFlightCommandBuffer();
  }

  async stopRun() {
    log("stop run...");
    await this.landNoWait();
    this._startConnectionPing();
  }

  async takeOff() {
    if (this.cancelRunFlag) return;

    if (this.batteryLevel <= 10) {
      throw new FlightError("Low Battery Warning - please charge your drone");
    }

    this._stopFlightPing();

    // if(this.flyingState == 'init') {
    //     throw new FlightError('Drone in init state, must be landed on flat surface to takeoff');
    // }
    log("Flying state:      ", this);
    log("Sending flat trim command...");
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      0,
      0,
      0,
    ]);
    log("Flat trim command sent");
    log("Sending takeoff command...");
    let writeResult = await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      0,
      1,
      0,
    ]);

    // if (!await this._waitUntilFlyingState(['hovering', 'flying'], 5000)) throw new FlightError('Error: Failed to Complete Takeoff');
    await this.wait(3);
    log("end of takeoff");
    log("write result of takeoff: ", writeResult);

    this._startFlightPing();
    return writeResult;
  }

  async land() {
    if (this.cancelRunFlag) return;

    this._resetFlightCommandBuffer();
    this._stopFlightPing();
    await sleep(50);

    log("Sending land command...");
    let writeResult = await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      0,
      3,
      0,
    ]);
    this._startFlightPing();
    // if (!await this._waitUntilFlyingState(['landed'], 5000)) throw new FlightError('Error: Failed to Land Properly');
    await this.wait(5);
    return writeResult;
  }

  async landNoWait() {
    this._resetFlightCommandBuffer();
    this._stopFlightPing();
    await sleep(51);
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      0,
      3,
      0,
    ]);
  }

  async hover() {
    this._resetFlightCommandBuffer();
    await this.wait(1);
  }

  reset() {
    this._resetFlightCommandBuffer();
  }

  async cutoff() {
    if (this.cancelRunFlag) return;

    log("Sending cutoff command");
    let writeResult = await this._writeCommand("fa00", "fa0c", [
      2,
      this.seq.fa0c++ % 255,
      2,
      0,
      4,
      0,
    ]);
    // if (!await this._waitUntilFlyingState(['landed'])) throw new FlightError('Error: Failed to Cutoff');
    await this.wait(2);
    return writeResult;
  }

  async rotate(degrees = 0, direction = "clockwise") {
    //check for valid args
    if (typeof degrees != "number") {
      console.log(
        "degrees parameter in drone.rotate(degrees, direction) must be a number",
      );
      console.log(degrees, typeof degrees);
      return;
    }
    if (direction != "clockwise" && direction != "counterclockwise") {
      console.log("invalid direction parameter passed to drone.rotate()");
      return;
    }

    log("begin rotate");
    let rotateSpeed = direction == "clockwise" ? 100 : -100;
    let turnForXSeconds = degrees / 180; //max rotate speed is set to 180 deg/sec upon connecting
    let driveSteps = turnForXSeconds * 20; //each drive step takes 50 ms
    let cmdBuffer = this.flightCommandBuffer;
    cmdBuffer.yaw = {
      consign: rotateSpeed,
      driveStepsRemaining: driveSteps,
    };
    await this.wait(turnForXSeconds);
    await this.wait(2);
    log("end rotate");
  }

  async fly(direction, seconds = 0, power = 0) {
    //check for valid args:
    if (
      !["up", "down", "left", "right", "forward", "backward"].includes(
        direction,
      )
    ) {
      console.log("invalid direction parameter passed to drone.fly()");
      return;
    }
    if (typeof seconds != "number") {
      console.log("invalid seconds parameter passed to drone.fly()");
      return;
    }
    if (typeof power != "number") {
      console.log("invalid power parameter passed to drone.fly()");
      return;
    }

    log("begin fly");
    if (power > 100) power = 100;
    if (power < -100) power = -100;
    if (seconds < 0) seconds = 0;
    let driveSteps = seconds * 20;

    let axis;

    switch (direction) {
      case "up":
        axis = "gaz";
        break;
      case "down":
        axis = "gaz";
        power = -power;
        break;
      case "right":
        axis = "roll";
        break;
      case "left":
        axis = "roll";
        power = -power;
        break;
      case "forward":
        axis = "pitch";
        break;
      case "backward":
        axis = "pitch";
        power = -power;
    }
    //update the flight command buffer, only on the specified axis/direction
    this.flightCommandBuffer[axis] = {
      consign: power,
      driveStepsRemaining: driveSteps,
    };
    await this.wait(seconds);
    await this.wait(2);
    log("end fly");
  }

  setAxis(axis, consign) {
    //validate args:
    if (!["pitch", "roll", "yaw", "altitude"].includes(axis)) {
      console.log("invalid axis parameter passed to drone.setAxis()");
      return;
    }

    if (consign > 100) consign = 100;
    if (consign < -100) consign = -100;

    this.flightCommandBuffer[axis].consign = consign;
    this.flightCommandBuffer[axis].driveStepsRemaining = Infinity;
  }

  async flip(direction) {
    //validate args:
    if (
      !["forward", "backward", "left", "right", undefined].includes(direction)
    ) {
      console.log("invalid direction parameter passed to drone.flip()");
      return;
    }
    if (this.cancelRunFlag) return;
    log("begin flip");
    this._stopFlightPing();

    // if(this.flyingState !== 'hovering') {
    //     throw new FlightError('Drone must be hovering in order to flip');
    // }

    let directionEnum;
    switch (direction) {
      case "forward":
        directionEnum = 0;
        break;
      case "backward":
        directionEnum = 1;
        break;
      case "right":
        directionEnum = 2;
        break;
      case "left":
        directionEnum = 3;
    }
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      4,
      0,
      0,
      directionEnum,
      0,
      0,
      0,
    ]);
    //sleeps for 2.5 seconds to allow the drone time to flip before continuing execution
    this._startFlightPing();
    await this.wait(2.5);
    log("end flip");
    return;
  }

  async forceLand() {
    log("cancelling run...");

    // if(this.flyingState == 'landed' || this.flyingState == 'init') {return;}
    //sends the emergency land cmd repeatedly for 3 seconds in case the drone is in the middle of flipping when it recieves the command
    let landInterval = setInterval(async () => {
      await this._writeCommand("fa00", "fa0c", [
        2,
        this.seq.fa0c++ % 255,
        2,
        0,
        3,
        0,
      ]);
    }, 50);
    await sleep(3000);
    clearInterval(landInterval);
    log("emergency land command sent...");
    return;
  }

  async waitUntilBatteryLevelChanges() {
    if (this.cancelRunFlag) return;

    log("beginning wait until battery changes...");
    let initialBatteryLevel = this.batteryLevel;
    //checks every 100ms if battery level has changed
    while (this.batteryLevel == initialBatteryLevel) {
      // log('waiting for battery level change...');
      // log('init battery level: ', initialBatteryLevel);
      // log('curr battery level: ', this.batteryLevel);
      log("still waiting...");
      await this.wait(0.1);
      if (this.cancelRunFlag) return;
    }
    return;
  }

  async wait(seconds) {
    if (typeof seconds != "number") {
      console.log(
        "invalid parameter passed to drone.wait() - must be a number",
      );
      return;
    }

    if (this.cancelRunFlag) return;

    let count = 0;
    while (!this.cancelRunFlag) {
      await sleep(100);
      if (++count > seconds * 10) return false;
    }
    return;
  }

  getBatteryLevel() {
    return this.batteryLevel;
  }

  async takePicture() {
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      6,
      1,
      0,
    ]);
  }

  async fireGun() {
    if (!this.gunAttached) {
      throw new FlightError("No gun accessory attached");
    }
    this._stopFlightPing();
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      16,
      2,
      0,
      this.gunUSBID,
      0,
      0,
      0,
      0,
    ]);
    this._startFlightPing();
    await this.wait(3);
  }

  async grabber(openOrClose) {
    if (!this.clawAttached) {
      throw new FlightError("No claw accessory attached");
    }
    let openOrCloseFlag;
    if (openOrClose == "OPEN") openOrCloseFlag = 0;
    else openOrCloseFlag = 1;
    this._stopFlightPing();
    await this._writeCommand("fa00", "fa0b", [
      2,
      this.seq.fa0b++ % 255,
      2,
      16,
      1,
      0,
      this.gunUSBID,
      openOrCloseFlag,
      0,
      0,
      0,
    ]);
    this._startFlightPing();
    await this.wait(2);
  }

  isFlying() {
    return this.flyingOrLanded == "flying";
  }

  isLanded() {
    return this.flyingOrLanded == "landed";
  }

  async _getService(shortUUID) {
    const service = await this.gattServer.getPrimaryService(
      this._getUUID(shortUUID),
    );
    return service;
  }

  async _getCharacteristic(serviceID, characteristicID) {
    const service = await this._getService(serviceID);
    const characteristic = await service.getCharacteristic(
      this._getUUID(characteristicID),
    );
    return characteristic;
  }

  async _writeCommand(serviceID, charID, commandArray) {
    //get characteristic to write to
    const characteristic = await this._getCharacteristic(serviceID, charID);
    try {
      //Pack command into buffer
      let buffer = new ArrayBuffer(commandArray.length);
      let command = new Uint8Array(buffer);
      command.set(commandArray);

      // log('Write command:      ', command);
      //returns the promise from characteristic.writeValue (though this is usually undefined I believe)
      return await characteristic.writeValue(command);
    } catch (error) {
      log("Write error:    " + error);
    }
  }

  _getUUID(shortUUID) {
    return "9a66" + shortUUID + "-0800-9191-11e4-012d1540cb8e";
  }

  _startFlightPing() {
    log("start flight ping...");
    //if ping was already restarted by a different thread(when using event blocks), don't want to start it again
    if (this.flightCommandPing) return;
    this.flightCommandPing = setInterval(async () => {
      // var d = new Date();
      // log(d.getTime());
      // log('Flight Ping...');
      let cmdBuffer = this.flightCommandBuffer;
      // log('pitch consign:        ', cmdBuffer['pitch']['consign']);
      // log('pitch drive steps:        ', cmdBuffer['pitch']['driveStepsRemaining']);
      // log('yaw consign:        ', cmdBuffer['yaw']['consign']);
      // log('yaw drive steps:        ', cmdBuffer['yaw']['driveStepsRemaining']);

      //For each axis: decrement it's remaining drive steps and check that they're still > 0
      for (const axis in cmdBuffer) {
        // log('checking drive steps');
        if (--cmdBuffer[axis].driveStepsRemaining < 0) {
          cmdBuffer[axis].consign = 0;
        }
      }
      let flag = cmdBuffer.pitch.consign != 0 || cmdBuffer.roll.consign != 0;
      let pcmd = [
        flag,
        cmdBuffer.roll.consign,
        cmdBuffer.pitch.consign,
        cmdBuffer.yaw.consign,
        cmdBuffer.gaz.consign,
      ];

      let flightCMD = [
        2,
        this.seq.fa0b++ % 255,
        2,
        0,
        2,
        0,
        ...pcmd,
        0,
        0,
        0,
        0,
      ];
      await this._writeCommand("fa00", "fa0b", flightCMD);
    }, 50);
  }

  _stopFlightPing() {
    log("stop flight ping...");
    clearInterval(this.flightCommandPing);
    this.flightCommandPing = null;
  }

  _startConnectionPing() {
    //if ping was already restarted by a different thread(when using event blocks), don't want to start it again
    if (this.connectionPing) return;
    this.connectionPing = setInterval(async () => {
      log("Connection Ping...");
      // await this._writeCommand('fa00', 'fa0a', [2, this.seq.fa0a++%255, 0, 4, 0, 0]);
      await this._writeCommand("fa00", "fa0a", [
        2,
        this.seq.fa0a++ % 255,
        2,
        14,
        0,
        0,
        0,
      ]);
    }, 2000);
  }

  _stopConnectionPing() {
    clearInterval(this.connectionPing);
    this.connectionPing = null;
  }

  _resetFlightCommandBuffer() {
    this.flightCommandBuffer = {
      roll: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      pitch: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      yaw: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
      gaz: {
        consign: 0,
        driveStepsRemaining: Infinity,
      },
    };
  }

  async _startNotifications(serviceID, characteristicID) {
    //starts listening for notifications on the specified characteristic
    const characteristic = await this._getCharacteristic(
      serviceID,
      characteristicID,
    );
    const updatedCharacteristic = await characteristic.startNotifications();
    characteristic.addEventListener("characteristicvaluechanged", (event) => {
      this._recievePacketFromDrone(event);
    });
    return updatedCharacteristic;
  }

  // _handleDisconnect() {
  //     log('handling unexpected disconnect...');
  //     this._stopConnectionPing();
  // }

  _recievePacketFromDrone(event) {
    //Handles data sent from the drone
    const array = new Uint8Array(event.target.value.buffer);
    let command = array.slice(2, 6);
    // log('PACKET FROM DRONE on characteristic: ', event.target.uuid.slice(4,8));
    // log(array);

    //Common.CommonState.BatteryStateChanged
    if (arraysEqual(command, [0, 5, 1, 0])) {
      let oldBatteryLevel = this.batteryLevel;
      this.batteryLevel = array[6];
      log("Battery level updated, now:    ", this.batteryLevel);
      if (this.batteryLevel != oldBatteryLevel) {
        this._emitEvent("batteryLevelChanged");
      }
    }
    //Minidrone.PilotingState.FlyingStateChanged
    if (arraysEqual(command, [2, 3, 1, 0])) {
      let newStateID = array[6];
      let oldFlyingState = this.flyingState;
      switch (newStateID) {
        case 0:
          this.flyingState = "landed";
          break;
        case 1:
          this.flyingState = "takingoff";
          break;
        case 2:
          this.flyingState = "hovering";
          break;
        case 3:
          this.flyingState = "flying";
          break;
        case 4:
          this.flyingState = "landing";
          break;
        case 5:
          this.flyingState = "emergency";
          break;
        case 6:
          this.flyingState = "rolling";
          break;
        case 7:
          this.flyingState = "init";
      }
      log("Updated flying state:    ", this.flyingState);
      if (
        oldFlyingState == "takingoff" &&
        ["hovering", "flying"].includes(this.flyingState)
      ) {
        this._emitEvent("flying");
        this.flyingOrLanded = "flying";
      }
      if (oldFlyingState == "landing" && this.flyingState == "landed") {
        this._emitEvent("landed");
        this.flyingOrLanded = "landed";
      }
      if (this.flyingState == "emergency") {
        this._emitEvent("crash");
      }
    }
    //Minidrone.SpeedSettingsState.MaxRotationSpeedChanged
    if (arraysEqual(command, [2, 5, 1, 0])) {
      log("Got max rotate speed update: ", array);
    }
    //Minidrone.UsbAccessoryState.GunState
    if (arraysEqual(command, [2, 15, 2, 0])) {
      let usbID = array[6];
      this.gunUSBID = usbID;
      let gunAttachedFlag = array[11];
      // log('gun flag:          ', gunAttachedFlag);
      if (gunAttachedFlag <= 3) this.gunAttached = true;
      else this.gunAttached = false;
    }
    //Minidrone.UsbAccessoryState.ClawState
    if (arraysEqual(command, [2, 15, 1, 0])) {
      let usbID = array[6];
      this.clawUSBID = usbID;
      let clawAttachedFlag = array[11];
      // log('gun flag:          ', gunAttachedFlag);
      if (clawAttachedFlag <= 3) this.clawAttached = true;
      else this.clawAttached = false;
    }
  }

  _emitEvent(eventType) {
    if (
      !["flying", "landed", "crash", "batteryLevelChanged"].includes(eventType)
    ) {
      console.warn("emitting drone event with no handler");
    }
    window.dispatchEvent(
      new CustomEvent("droneEvent", { detail: { eventType: eventType } }),
    );
  }

  // async _waitUntilFlyingState(states, timeout=3000) {
  //     //helper function that waits until the drone's FlyingState matches one of the given states
  //     //returns true after the state matches, false after $timeout$ milliseconds of waiting
  //     let count = 0;
  //     while(!states.includes(this.flyingState)) {
  //         await this.wait(0.1);
  //         if(++count > (timeout/100)) return false;
  //     }
  //     log('done waiting!', this.flyingState);
  //     return true;
  // }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export { MamboController, FlightError };
