import * as Blockly from 'blockly/core';

Blockly.Python['minidrone_takeoff'] = function() {
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.take_off()\n';
    return code;
  };
  
  Blockly.Python['minidrone_land'] = function() {
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.land()\n';
    return code;
  };
  
  Blockly.Python['minidrone_rotate'] = function(block) {
    var value_degrees = Blockly.Python.valueToCode(block, 'DEGREES', Blockly.Python.ORDER_ATOMIC);
    var dropdown_direction = block.getFieldValue('direction');
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.rotate(degrees=' + value_degrees + ', direction="' + dropdown_direction + '")\n';
    return code;
  };
  
  Blockly.Python['minidrone_fly'] = function(block) {
    var dropdown_direction = block.getFieldValue('direction');
    var value_seconds = Blockly.Python.valueToCode(block, 'seconds', Blockly.Python.ORDER_ATOMIC);
    var value_power = Blockly.Python.valueToCode(block, 'power', Blockly.Python.ORDER_ATOMIC);
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.fly(direction="' + dropdown_direction + '", seconds=' + value_seconds + ', power=' + value_power + ')\n';
    return code;
  };
  
  Blockly.Python['minidrone_flip'] = function(block) {
    var dropdown_direction = block.getFieldValue('direction');
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.flip("' + dropdown_direction + '")\n';
    return code;
  };
  
  Blockly.Python['minidrone_cutoff'] = function() {
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.cutoff()\n';
    return code;
  };
  
  Blockly.Python['minidrone_get_battery_level'] = function() {
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.get_battery_level()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.Python.ORDER_FUNCTION_CALL];
  };

  Blockly.Python['minidrone_take_picture'] = function() {
    // TODO: Assemble Python into code variable.
    var code = 'drone.take_picture()\n';
    return code;
  };

  Blockly.Python['minidrone_fire_bb'] = function() {
    // TODO: Assemble Python into code variable.
    var code = 'drone.fire_bb()\n';
    return code;
  };
  
  Blockly.Python['program_start'] = function(block) {
    var statements_main_code = Blockly.Python.statementToCode(block, 'main_code');
    // TODO: Assemble JavaScript into code variable.
    var code = 'def main():\n'+statements_main_code;
    return code;
  };
  
  Blockly.Python['wait'] = function(block) {
    var value_seconds = Blockly.Python.valueToCode(block, 'seconds', Blockly.Python.ORDER_ATOMIC);
    // TODO: Assemble JavaScript into code variable.
    var code = 'time.sleep('+value_seconds+')\n';
    return code;
  };
  
  Blockly.Python['minidrone_wait_until_battery_changes'] = function() {
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.wait_until_battery_level_changes()\n';
    return code;
  };
  
  Blockly.Python['minidrone_flying_state'] = function(block) {
    var dropdown_flying_state = block.getFieldValue('flying_state');
    // TODO: Assemble JavaScript into code variable.
    var code = 'drone.flying_state == "' + dropdown_flying_state + '"';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.Python.ORDER_ATOMIC];
  };

  Blockly.Python['custom_print'] = function(block) {
    var value_message = Blockly.Python.valueToCode(block, 'message', Blockly.Python.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = 'print('+value_message+')\n';
    return code;
  };
  
  Blockly.Python['repeat_forever'] = function(block) {
    var statements_block_of_code = Blockly.Python.statementToCode(block, 'block_of_code');
    // TODO: Assemble JavaScript into code variable.
    var code = 'while True:\n'+statements_block_of_code+'\n';
    return code;
  };

  Blockly.Python['repeat_seconds'] = function(block) {
    var value_seconds = Blockly.Python.valueToCode(block, 'seconds', Blockly.Python.ORDER_ATOMIC);
    var statements_do = Blockly.Python.statementToCode(block, 'do');
    // TODO: Assemble Python into code variable.
    var code = 'start_time = time.time() * 1000\nwhile time.time()*1000 < startTime + '+value_seconds+' * 1000:\n'+statements_do+'\n';
    return code;
  };

  Blockly.Python['stop_program'] = function() {
    // TODO: Assemble Python into code variable.
    var code = 'return\n';
    return code;
  };

  Blockly.Python['is_key_pressed'] = function(block) {
    var dropdown_key = block.getFieldValue('key');
    var dropdown_pressed_or_released = block.getFieldValue('pressed_or_released');
    // TODO: Assemble Python into code variable.
    var code = 'getKeyState('+dropdown_key+', '+dropdown_pressed_or_released+')';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.Python.ORDER_NONE];
  };

  Blockly.Python['continue_if'] = function(block) {
    var value_condition = Blockly.Python.valueToCode(block, 'condition', Blockly.Python.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = 'if not '+value_condition+':\n'+Blockly.Python.INDENT+'return\n';
    return code;
  };

  Blockly.Python['event_when_key_pressed'] = function(block) {
    var dropdown_key = block.getFieldValue('key');
    var dropdown_pressed_or_released = block.getFieldValue('pressed_or_released');
    var statements_do = Blockly.Python.statementToCode(block, 'do') || Blockly.Python.INDENT+'pass\n';
    // TODO: Assemble JavaScript into code variable.
    var code = 'def on_key_event("'+dropdown_pressed_or_released+'", "'+dropdown_key+'"):\n'+statements_do;
    return code;
  };

  Blockly.Python['minidrone_hover'] = function() {
    // TODO: Assemble Python into code variable.
    var code = 'drone.hover()\n';
    return code;
  };

  Blockly.Python['minidrone_set_direction'] = function(block) {
    var dropdown_axis = block.getFieldValue('axis');
    var value_name = Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = 'drone.set_direction("'+dropdown_axis+'", '+value_name+')\n';
    return code;
  };

  Blockly.Python['event_when_minidrone_flying_state'] = function(block) {
    var dropdown_state = block.getFieldValue('STATE');
    var statements_do = Blockly.Python.statementToCode(block, 'DO') || Blockly.Python.INDENT+'pass\n';
    // TODO: Assemble Python into code variable.
    var code = 'def when_minidrone_state("'+dropdown_state+'):\n'+statements_do;
    return code;
  };

  Blockly.Python['event_when_minidrone_crashes'] = function(block) {
    var statements_do = Blockly.Python.statementToCode(block, 'DO') || Blockly.Python.INDENT+'pass\n';
    // TODO: Assemble Python into code variable.
    var code = 'def on_minidrone_crash():\n'+statements_do;
    return code;
  };

  Blockly.Python['event_when_minidrone_battery_level_changes'] = function(block) {
    var statements_do = Blockly.Python.statementToCode(block, 'DO') || Blockly.Python.INDENT+'pass\n';
    // TODO: Assemble Python into code variable.
    var code = 'def on_battery_level_change():\n'+statements_do;
    return code;
  };