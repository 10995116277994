<template>
  <header>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.ftw-robotics.com/"
    >
      <img id="ftwLogo" src="../assets/ftw_logo_white.png" alt="FTW Robotics" />
    </a>
    <div class="topbarRight">
      <svg @click="isDropdownOpen=!isDropdownOpen" class="dropdown-toggle" :class="{'toggle-open': isDropdownOpen}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="37.5pt" height="37.499999pt" viewBox="0 0 37.5 37.499999" version="1.2">
        <defs>
        <clipPath id="clip1">
          <path d="M 1.410156 6.566406 L 35.734375 6.566406 L 35.734375 8 L 1.410156 8 Z M 1.410156 6.566406 "/>
        </clipPath>
        <clipPath id="clip2">
          <path d="M 1.410156 13 L 35.734375 13 L 35.734375 15 L 1.410156 15 Z M 1.410156 13 "/>
        </clipPath>
        <clipPath id="clip3">
          <path d="M 1.410156 20 L 35.734375 20 L 35.734375 22 L 1.410156 22 Z M 1.410156 20 "/>
        </clipPath>
        <clipPath id="clip4">
          <path d="M 1.410156 28 L 35.734375 28 L 35.734375 30 L 1.410156 30 Z M 1.410156 28 "/>
        </clipPath>
        </defs>
        <g id="surface1">
        <g clip-path="url(#clip1)" clip-rule="nonzero">
        <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 35.195312 7.601562 L 1.949219 7.601562 C 1.664062 7.601562 1.429688 7.371094 1.429688 7.085938 C 1.429688 6.800781 1.664062 6.566406 1.949219 6.566406 L 35.195312 6.566406 C 35.484375 6.566406 35.714844 6.800781 35.714844 7.085938 C 35.714844 7.371094 35.484375 7.601562 35.195312 7.601562 "/>
        </g>
        <g clip-path="url(#clip2)" clip-rule="nonzero">
        <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 35.195312 14.792969 L 1.949219 14.792969 C 1.664062 14.792969 1.429688 14.558594 1.429688 14.273438 C 1.429688 13.988281 1.664062 13.757812 1.949219 13.757812 L 35.195312 13.757812 C 35.484375 13.757812 35.714844 13.988281 35.714844 14.273438 C 35.714844 14.558594 35.484375 14.792969 35.195312 14.792969 "/>
        </g>
        <g clip-path="url(#clip3)" clip-rule="nonzero">
        <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 35.195312 21.980469 L 1.949219 21.980469 C 1.664062 21.980469 1.429688 21.75 1.429688 21.464844 C 1.429688 21.175781 1.664062 20.945312 1.949219 20.945312 L 35.195312 20.945312 C 35.484375 20.945312 35.714844 21.175781 35.714844 21.464844 C 35.714844 21.75 35.484375 21.980469 35.195312 21.980469 "/>
        </g>
        <g clip-path="url(#clip4)" clip-rule="nonzero">
        <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 35.195312 29.167969 L 1.949219 29.167969 C 1.664062 29.167969 1.429688 28.9375 1.429688 28.652344 C 1.429688 28.367188 1.664062 28.132812 1.949219 28.132812 L 35.195312 28.132812 C 35.484375 28.132812 35.714844 28.367188 35.714844 28.652344 C 35.714844 28.9375 35.484375 29.167969 35.195312 29.167969 "/>
        </g>
        </g>
      </svg>
      <div class="dropdown-menu" v-if="isDropdownOpen" @click="isDropdownOpen = false">
        <a target="_blank" rel="noopener noreferrer" href="https://www.ftw-robotics.com/portal">
          BUILD|FLY|CODE Portal
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.ftw-robotics.com/support">
          Help
        </a>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      isDropdownOpen: false,
    };
  },
};
</script>

<style scoped>
header {
  background-color: black;
  color: white;
}
#ftwLogo {
  height: 48px;
  width: auto;
  padding-left: 10px;
  padding-top: 6px;
}
.topbarRight {
  float: right;
  height: 100%;
}
.topbarRight button:hover {
  border: 2px solid white;
}
.dropdown-toggle {
  fill: white;
  cursor: pointer;
  height: 48px;
  width: auto;
  padding-right: 10px;
  padding-top: 6px;
}
.dropdown-toggle:hover {
  fill: #6f0;
}
.dropdown-menu {
  position: absolute;
  right: 0;
  z-index: 100;
  background-color: black;
}
.dropdown-menu a {
  display: block;
  padding: 12px;
  text-decoration: none;
  color: white;
}
.dropdown-menu a:hover {
  color: #6f0;
}
</style>
