import CodeMirror from 'codemirror';

CodeMirror.hint.drone = function(editor) {
    const contextualCompletions = {
        'drone.': [
            {
                text: 'takeOff()',
                displayText: 'takeOff()'
            },
            {
                text: 'land()',
                displayText: 'land()'
            },
            {
                text: 'hover()',
                displayText: 'hover()'
            },
            {
                text: 'cutoff()',
                displayText: 'cutoff()'
            },
            {
                text: 'fly("forward", 1, 50)',
                displayText: 'fly("forward", 1, 50)'
            },
            {
                text: 'rotate(180, "clockwise")',
                displayText: 'rotate(180, "clockwise")'
            },
            {
                text: 'flip()',
                displayText: 'flip()'
            },
            {
                text: 'setAxis("pitch", 50)',
                displayText: 'setAxis("pitch", 50)'
            },
            {
                text: 'fireGun()',
                displayText: 'fireGun()'
            },
            {
                text: 'takePicture()',
                displayText: 'takePicture()'
            },
            {
                text: 'wait(1)',
                displayText: 'wait(1)'
            },
            {
                text: 'getBatteryLevel()',
                displayText: 'getBatteryLevel()'
            },
            {
                text: 'isLanded()',
                displayText: 'isLanded()'
            },
            {
                text: 'isFlying()',
                displayText: 'isFlying()'
            },
            
        ],
        'droneEventListeners.': [
            {
                text: 'flying',
                displayText: 'flying'
            },
            {
                text: 'landed',
                displayText: 'landed'
            },
            {
                text: 'crashed',
                displayText: 'crashed'
            },
            {
                text: 'batteryLevelChanged',
                displayText: 'batteryLevelChanged'
            },
        ],
        'keyPressListeners.': [
            {
                text: 'pressed',
                displayText: 'pressed'
            },
            {
                text: 'released',
                displayText: 'released'
            }
        ],
        'keyPressListeners.pressed.': [
            {
                text: 'ArrowUp',
                displayText: 'ArrowUp'
            },
            {
                text: 'ArrowDown',
                displayText: 'ArrowDown'
            },
            {
                text: 'ArrowLeft',
                displayText: 'ArrowLeft'
            },
            {
                text: 'ArrowRight',
                displayText: 'ArrowRight'
            },
            {
                text: 'Space',
                displayText: 'Space'
            },
            {
                text: 'w',
                displayText: 'w'
            },
            {
                text: 'a',
                displayText: 'a'
            },
            {
                text: 's',
                displayText: 's'
            },
            {
                text: 'd',
                displayText: 'd'
            },
        ],
        'keyPressListeners.released.': [
            {
                text: 'ArrowUp',
                displayText: 'ArrowUp'
            },
            {
                text: 'ArrowDown',
                displayText: 'ArrowDown'
            },
            {
                text: 'ArrowLeft',
                displayText: 'ArrowLeft'
            },
            {
                text: 'ArrowRight',
                displayText: 'ArrowRight'
            },
            {
                text: 'Space',
                displayText: 'Space'
            },
            {
                text: 'w',
                displayText: 'w'
            },
            {
                text: 'a',
                displayText: 'a'
            },
            {
                text: 's',
                displayText: 's'
            },
            {
                text: 'd',
                displayText: 'd'
            },
        ],
        'keysPressed.': [
            {
                text: 'ArrowUp',
                displayText: 'ArrowUp'
            },
            {
                text: 'ArrowDown',
                displayText: 'ArrowDown'
            },
            {
                text: 'ArrowLeft',
                displayText: 'ArrowLeft'
            },
            {
                text: 'ArrowRight',
                displayText: 'ArrowRight'
            },
            {
                text: 'Space',
                displayText: 'Space'
            },
            {
                text: 'w',
                displayText: 'w'
            },
            {
                text: 'a',
                displayText: 'a'
            },
            {
                text: 's',
                displayText: 's'
            },
            {
                text: 'd',
                displayText: 'd'
            },
        ],
    };
    
    const contextFreeCompletions = [
        {
            text: 'drone',
            displayText: 'drone'
        },
        {
            text: 'console',
            displayText: 'console'
        },
        {
            text: 'await',
            displayText: 'await'
        },
        {
            text: 'async',
            displayText: 'async'
        },
        {
            text: 'droneEventListeners',
            displayText: 'droneEventListeners'
        },
        {
            text: 'keyPressListeners',
            displayText: 'keyPressListeners'
        },
        {
            text: 'keysPressed',
            displayText: 'keysPressed'
        },
        // {
        //     text: 'async function () {\n\t\n}',
        //     displayText: 'async function () {\n}',
        //     altText: 'function'
        // },
        // {
        //     text: 'function () {\n\t\n}',
        //     displayText: 'function () {\n}',
        // },
        {
            text: 'startProgram = async function () {\n\t\n};',
            displayText: 'startProgram'
        },
        {
            text: 'stopProgram()',
            displayText: 'stopProgram()'
        },
        
        
    ];
    // let droneFunctions = ['fly', 'rotate', 'takeOff', {text: 'land()', displayText: 'land - tells the drone to land\nmust be used with await'}];
    let cur = editor.getCursor(), token = editor.getTokenAt(cur), Pos = CodeMirror.Pos;

    //get the context if the user is accessing the property of drone, droneEventListeners etc
    let context = getContext(editor, token, cur);

    //grab the list of possible completions given the context
    let completions;
    if (context && context in contextualCompletions) {
        completions = contextualCompletions[context];
        if (token.string == '.') {
            completions.forEach(completion => {
                completion.text = '.' + completion.text;
            });
        }
    } else {
        completions = contextFreeCompletions;
    }

    //filter the possible completions based on what's been typed so far
    completions = completions.filter(c => c.text.toLowerCase().startsWith(token.string.toLowerCase()) || c.altText && c.altText.startsWith(token.string));

    //format the completions for codemirror
    let result = {list: completions, from: Pos(cur.line, token.start), to: Pos(cur.line, token.end)};

    //in certain cases, move the cursor or adjust autocompletion after a completion is selected
    CodeMirror.on(result, 'close', function() {
        let cur = editor.getCursor();
        let tok = editor.getTokenAt(cur);

        //move cursor inside function def brackets
        if(tok.string == '}' || tok.string == ';'){
            editor.setCursor(CodeMirror.Pos(cur.line-1,2));
        }

        let nextToken = editor.getTokenAt(CodeMirror.Pos(cur.line, cur.ch+1));
        if (tok.string == ')' && nextToken.string == '('){
            editor.execCommand('delCharBefore');
            editor.execCommand('delCharAfter');
            editor.setCursor(CodeMirror.Pos(cur.line, cur.ch+1))
        }
    });

    return result;
}

function getContext (editor, token, cursor) {
    let context = '';
    let tempToken = token;
    while (tempToken.type == 'property' || tempToken.string=='.') {
        if(tempToken.string!='.') tempToken = getPrevToken(editor, tempToken, cursor);
        if(tempToken.string!='.') return '';
        tempToken = getPrevToken(editor, tempToken, cursor);
        context = tempToken.string + '.' + context;
    }
    return context;
}

function getPrevToken(editor, curToken, cursor) {
    return editor.getTokenAt(CodeMirror.Pos(cursor.line, curToken.start));
}